import {
  Button,
  Card,
  Form,
  Typography,
  message,
  Checkbox,
  Select,
} from 'antd';
import React from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/selectors/user';
import {
  ApiPlatformSettingsGetResponse,
  ApiPlatformSettingsUpdateRequest,
} from '../../services/api/types/platform-settings';
import {
  apiGetPlatformSettings,
  apiUpdatePlatformSettings,
} from '../../services/api/platform-settings';
import { AIFormVersion } from '../../services/api/types/ai';

interface PlatformSettingsEditFormValue {
  loadingWatermark: boolean;
  aiFormVersion: AIFormVersion;
}

function PlatformSettingsEdit() {
  const { token } = useSelector(selectUserState);
  const navigate = useNavigate();
  const [saving, setSaving] = React.useState(false);
  const [response, setResponse] =
    React.useState<ApiPlatformSettingsGetResponse | null>(null);
  const [form] = Form.useForm<PlatformSettingsEditFormValue>();

  async function loadPlatformData(tk: string) {
    const [response, status] = await apiGetPlatformSettings(tk);
    if (status === 200) {
      setResponse(response);
    } else {
      message.error({ content: 'Error loading form!' });
      navigate('/');
    }
  }

  React.useEffect(() => {
    if (token) {
      loadPlatformData(token);
    }
  }, [token]);

  const formHandleUpdate = React.useCallback(
    async (values: PlatformSettingsEditFormValue) => {
      setSaving(true);
      if (response?.aliasId && token && values && Object.keys(values).length) {
        const updateBody: ApiPlatformSettingsUpdateRequest = {};

        if (values.loadingWatermark !== response.loadingWatermark) {
          updateBody.loadingWatermark = values.loadingWatermark;
        }

        if (values.aiFormVersion !== response.aiFormVersion) {
          updateBody.aiFormVersion = values.aiFormVersion;
        }

        if (Object.keys(updateBody).length === 0) {
          message.info('No changes detected!');
          setSaving(false);
          return;
        }

        const [, status] = await apiUpdatePlatformSettings(token, updateBody);

        if (status === 201) {
          message.success('Platform settings updated successfully!');
          loadPlatformData(token);
        } else {
          message.error('Failed to update platform settings!');
        }
      }
      setSaving(false);
    },
    [token, response],
  );

  return (
    <Card bordered={false} title="Platform Settings">
      <Typography.Title level={2}>Platform Settings Edit</Typography.Title>
      {response?.aliasId ? (
        <Form form={form} onFinish={formHandleUpdate} disabled={saving}>
          <Form.Item
            label="Loading Watermark"
            name="loadingWatermark"
            valuePropName="checked"
            initialValue={response.loadingWatermark}
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            label="AI Form Version"
            name="aiFormVersion"
            initialValue={response.aiFormVersion}
          >
            <Select>
              {Object.values(AIFormVersion).map((version) => (
                <Select.Option key={version} value={version}>
                  {version}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save Changes
            </Button>
          </Form.Item>
        </Form>
      ) : null}
    </Card>
  );
}

export default PlatformSettingsEdit;
