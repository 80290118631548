import { FormEntity } from './forms';
import { OptionEntity } from './options';

export enum InputType {
  SINGLE_SELECT = 'single-select',
  MULTIPLE_SELECT = 'multiple-select',
  PHONE_INPUT = 'phone-input',
  TEXT_INPUT = 'text-input',
  EMAIL_INPUT = 'email-input',
  DATE_INPUT = 'date-input',
  DATE_RANGE_INPUT = 'date-range-input',
  NUMBER_INPUT = 'number-input',
  ADDRESS_INPUT = 'address-input',
  TEXTAREA_INPUT = 'textarea-input',
}

export enum DesignType {
  CARDS = 'cards',
  BULLETS = 'bullets',
}

export enum ViewType {
  LIST = 'list',
  GRID = 'grid',
}

export const ALLOWED_INPUT_TYPES = [...Object.values(InputType)];

export const INPUT_TYPES_WITH_LABEL = [
  InputType.PHONE_INPUT,
  InputType.TEXT_INPUT,
  InputType.EMAIL_INPUT,
  InputType.DATE_INPUT,
  InputType.NUMBER_INPUT,
  InputType.ADDRESS_INPUT,
  InputType.DATE_RANGE_INPUT,
];

export const INPUT_TYPES_WITH_SECOND_LABEL = [InputType.DATE_RANGE_INPUT];

export const INPUT_TYPES_WITH_OPTIONS = [
  InputType.SINGLE_SELECT,
  InputType.MULTIPLE_SELECT,
];

export interface InputEntity {
  id: number;
  createdAt: string;
  modifiedAt: string;
  deletedAt: null;
  question: string;
  order: number;
  inputType: InputType;
  designType: DesignType;
  viewType: ViewType;
  options?: OptionEntity[];
  step?: StepEntity;
  dataAnnotation: string | null;
  inputLabel: string | null;
  inputLabelSecond: string | null;
  optional: boolean;
}

export interface StepEntity {
  id: number;
  createdAt: string;
  modifiedAt: string;
  deletedAt: null;
  title: string;
  description: null;
  isFinalStep: boolean;
  order: number;
  submitButtonText: string;
  stepType: string;
  inputs?: InputEntity[];
  form?: FormEntity;
  nextStep?: StepEntity;
}

export interface ApiStepsGetResponse {
  data: StepEntity;
}

export interface ApiStepsUpdateRequestBody {
  stepId: number;
  title?: string;
  description?: string | null;
  order?: number;
  submitButtonText?: string;
  isFinalStep?: boolean;
  nextStepId?: number | null;
  inputLabel?: string;
  inputType?: InputType;
  dataAnnotation?: string | null;
  designType?: DesignType;
  viewType?: ViewType;
  optional?: boolean;
}

export interface ApiStepsUpdateResponse {
  data: StepEntity;
}

export interface ApiStepsCreateRequestBody {
  formId: number;
  isFinalStep: boolean;
  order: number;
  submitButtonText: string;
  title: string;
  description?: string | null;
  options: {
    imageId: number;
    order: number;
    value: string;
    resultDescription?: string;
    resultTitle?: string;
    resultMinPrice?: number;
    resultImageId?: number;
  }[];
  inputLabel?: string | null;
  inputLabelSecond?: string | null;
  inputType: InputType;
  designType: DesignType;
  viewType: ViewType;
  optional?: boolean;
}

export interface ApiStepsCreateResponse {
  data: StepEntity;
}

export interface ApiStepsDeleteResponse {
  data: StepEntity;
}
