import { API_URL } from './constants';
import {
  ApiCNAMEAddRequest,
  ApiCNAMEAddResponse,
  ApiCNAMEDeleteRequest,
  ApiCNAMEDeleteResponse,
  ApiCNAMEGetRequest,
  ApiCNAMEGetResponse,
  ApiCNAMEListRequest,
  ApiCNAMEListResponse,
  ApiCNAMEVerifyRequest,
  ApiCNAMEVerifyResponse,
} from './types/cname';

export async function apiCNAMEAdd(
  token: string,
  body: ApiCNAMEAddRequest,
): Promise<[ApiCNAMEAddResponse, number]> {
  const url = new URL(`${API_URL}/admin/cname/add`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [(await response.json()) as ApiCNAMEAddResponse, response.status];
}

export async function apiCNAMEDelete(
  token: string,
  body: ApiCNAMEDeleteRequest,
): Promise<[ApiCNAMEDeleteResponse, number]> {
  const url = new URL(`${API_URL}/admin/cname/delete`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [(await response.json()) as ApiCNAMEDeleteResponse, response.status];
}

export async function apiCNAMEGet(
  token: string,
  body: ApiCNAMEGetRequest,
): Promise<[ApiCNAMEGetResponse, number]> {
  const url = new URL(`${API_URL}/admin/cname/get`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [(await response.json()) as ApiCNAMEGetResponse, response.status];
}

export async function apiCNAMEList(
  token: string,
  body: ApiCNAMEListRequest,
): Promise<[ApiCNAMEListResponse, number]> {
  const url = new URL(`${API_URL}/admin/cname/list`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [(await response.json()) as ApiCNAMEListResponse, response.status];
}

export async function apiCNAMEVerify(
  token: string,
  body: ApiCNAMEVerifyRequest,
): Promise<[ApiCNAMEVerifyResponse, number]> {
  const url = new URL(`${API_URL}/admin/cname/verify`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [(await response.json()) as ApiCNAMEVerifyResponse, response.status];
}
