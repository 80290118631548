import { Button, Menu, MenuProps, Modal } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { RouterPaths } from '../../router/paths';
import {
  UserOutlined,
  PlusSquareOutlined,
  FormOutlined,
  UnorderedListOutlined,
  RightSquareOutlined,
  FontSizeOutlined,
  BarChartOutlined,
  UserAddOutlined,
  ShopOutlined,
  SnippetsOutlined,
  MailOutlined,
  ControlOutlined,
  DollarOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router';
import React from 'react';
import { useDispatch } from 'react-redux';
import { actionUserLogout } from '../../store/actions/user.actions';
import { Header } from 'antd/es/layout/layout';

type MenuItem = Required<MenuProps>['items'][number];

function SideNavigation() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [mobileMenuOpened, setMobileMenuOpened] =
    React.useState<boolean>(false);

  const navigationItems: MenuItem[] = [
    {
      key: RouterPaths.STATISTICS,
      icon: <BarChartOutlined />,
      label: 'Statistics',
      onClick: () => {
        navigate(RouterPaths.STATISTICS);
      },
    },
    {
      key: RouterPaths.FEEDBACKS,
      icon: <SnippetsOutlined />,
      label: 'Feedbacks',
      onClick: () => {
        navigate(RouterPaths.FEEDBACKS);
      },
    },
    {
      key: RouterPaths.SEND_ANNOUNCEMENT,
      icon: <MailOutlined />,
      label: 'Send Announcement',
      onClick: () => {
        navigate(RouterPaths.SEND_ANNOUNCEMENT);
      },
      style: { marginBottom: window.innerWidth <= 1024 ? 0 : 25 },
    },
    {
      key: RouterPaths.REGISTRATIONS,
      icon: <UserAddOutlined />,
      label: 'Registrations',
      onClick: () => {
        navigate(RouterPaths.REGISTRATIONS);
      },
    },
    {
      key: RouterPaths.USERS,
      icon: <UserOutlined />,
      label: 'Users',
      onClick: () => {
        navigate(RouterPaths.USERS);
      },
    },
    {
      key: RouterPaths.AGENCY_USERS,
      icon: <ShopOutlined />,
      label: 'Agencies',
      onClick: () => {
        navigate(RouterPaths.AGENCY_USERS);
      },
      style: { marginBottom: window.innerWidth <= 1024 ? 0 : 25 },
    },
    {
      key: RouterPaths.FORMS,
      icon: <FormOutlined />,
      label: 'Forms',
      onClick: () => {
        navigate(RouterPaths.FORMS);
      },
    },
    {
      key: RouterPaths.STEPS,
      icon: <RightSquareOutlined />,
      label: 'Form Steps',
      onClick: () => {
        navigate(RouterPaths.STEPS);
      },
    },
    {
      key: RouterPaths.OPTIONS,
      icon: <UnorderedListOutlined />,
      label: 'Form Options',
      onClick: () => {
        navigate(RouterPaths.OPTIONS);
      },
    },
    {
      key: RouterPaths.TRANSLATIONS,
      icon: <FontSizeOutlined />,
      label: 'Translations',
      onClick: () => {
        navigate(RouterPaths.TRANSLATIONS);
      },
      style: { marginBottom: window.innerWidth <= 1024 ? 0 : 25 },
    },
    {
      key: RouterPaths.CREATE_WIZARD,
      icon: <PlusSquareOutlined />,
      label: 'Create Form',
      onClick: () => {
        navigate(RouterPaths.CREATE_WIZARD);
      },
    },
    {
      key: RouterPaths.FEATURE_LIMITS,
      icon: <ControlOutlined />,
      label: 'Feature Limits',
      onClick: () => {
        navigate(RouterPaths.FEATURE_LIMITS);
      },
    },
    {
      key: RouterPaths.BILLING_SETTINGS,
      icon: <DollarOutlined />,
      label: 'Billing Settings',
      onClick: () => {
        navigate(RouterPaths.BILLING_SETTINGS);
      },
    },
    {
      key: RouterPaths.PLATFORM_SETTINGS_EDIT,
      icon: <SettingOutlined />,
      label: 'Platform Settings',
      onClick: () => {
        navigate(RouterPaths.PLATFORM_SETTINGS_EDIT);
      },
    },
  ];

  const defaultSelected = React.useMemo(() => {
    const locationFound = Object.values(RouterPaths).find((rp) => {
      return location.pathname.includes(rp);
    });
    if (locationFound) {
      return [locationFound];
    }
    return [];
  }, [location]);

  if (window.innerWidth < 1024) {
    return (
      <Header
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}
      >
        <Button
          type="primary"
          onClick={() => {
            setMobileMenuOpened(true);
          }}
        >
          Menu
        </Button>
        <Button
          type="primary"
          onClick={() => {
            dispatch(actionUserLogout());
          }}
        >
          Logout
        </Button>
        <Modal
          open={mobileMenuOpened}
          onCancel={() => {
            setMobileMenuOpened(false);
          }}
          onOk={() => {
            setMobileMenuOpened(false);
          }}
        >
          <Menu
            theme="dark"
            selectedKeys={defaultSelected}
            items={navigationItems}
            mode="inline"
            onSelect={() => {
              setMobileMenuOpened(false);
            }}
          />
        </Modal>
      </Header>
    );
  }
  return (
    <Sider
      style={{
        padding: '0px',
        maxHeight: '100vh',
        position: 'sticky',
        top: 0,
        overflow: 'scroll',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          flexDirection: 'column',
          height: '100%',
          padding: '25px 10px 100px',
        }}
      >
        <Menu
          theme="dark"
          selectedKeys={defaultSelected}
          items={navigationItems}
        />
        <Button
          style={{ margin: '25px 0 25px' }}
          type="primary"
          onClick={() => {
            dispatch(actionUserLogout());
          }}
        >
          Logout
        </Button>
      </div>
    </Sider>
  );
}

export default SideNavigation;
