import { Card, message } from 'antd';
import React, { useEffect } from 'react';
import FeatureLimitsEdit from '../../components/FeatureLimitsEdit';
import { useNavigate, useParams } from 'react-router';
import { RouterPaths } from '../../router/paths';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/selectors/user';
import { ApiUsersGetResponse } from '../../services/api/types/users';
import { apiUsersGet } from '../../services/api/users';

function UserFeatureLimitsPage() {
  const navigate = useNavigate();
  const { userId } = useParams();
  const { token } = useSelector(selectUserState);
  const [loadedUser, setLoadedUser] =
    React.useState<ApiUsersGetResponse | null>(null);

  async function loadUser(tk: string, uid: number) {
    const [response, status] = await apiUsersGet(uid, tk);
    if (status === 200 && response) {
      setLoadedUser(response);
    } else {
      message.error({ content: 'User not found!' });
      navigate(RouterPaths.USERS);
    }
  }

  useEffect(() => {
    if (token) {
      if (!userId) {
        message.error({ content: 'User not found!' });
        navigate(RouterPaths.USERS);
      } else {
        loadUser(token, parseInt(userId, 10));
      }
    }
  }, [userId]);

  if (!userId) {
    return null;
  }

  return (
    <Card title="User Feature Limits">
      <div>
        {loadedUser ? <FeatureLimitsEdit userId={loadedUser.aliasId} /> : null}
      </div>
    </Card>
  );
}

export default UserFeatureLimitsPage;
