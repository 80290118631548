/* eslint-disable @typescript-eslint/consistent-type-assertions */
import {
  Button,
  Card,
  Checkbox,
  DatePicker,
  DatePickerProps,
  Input,
  Typography,
  message,
} from 'antd';
import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './index.scss';
import SenderSelectorComponent from '../../components/SenderSelector';
import AnnouncementRecipientSelector from '../../components/AnnouncementRecipientSelector';
import { MailApiSendAnnouncementRequestBody } from '../../services/api/types/mail';
import { apiMailSendAnnouncement } from '../../services/api/mail';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/selectors/user';

export default function SendAnnoouncementPage() {
  const { token } = useSelector(selectUserState);
  const [loading, setLoading] = React.useState(false);
  const [scheduleEnabled, setScheduleEnabled] = React.useState(false);
  const [body, setBody] = React.useState<{
    account: string | null;
    subject: string | null;
    content: string | null;
    scheduleAt: Date | null;
    recipients: {
      email: string;
      name: string;
    }[];
  }>({
    account: null,
    subject: null,
    content: null,
    recipients: [],
    scheduleAt: null,
  });

  function checkIfValid() {
    if (loading) {
      return false;
    }
    if (!body.account) {
      return false;
    }
    if (!body.subject) {
      return false;
    }
    if (!body.content) {
      return false;
    }
    if (!body.recipients.length) {
      return false;
    }
    return true;
  }

  function onOk(value: DatePickerProps['value']) {
    console.log(value?.toDate());
    setBody({
      ...body,
      scheduleAt: value?.toDate() || null,
    });
  }

  async function submit() {
    if (checkIfValid() && token) {
      setLoading(true);
      let content = body.content as string;
      while (content.includes(`class="ql-size-small"`)) {
        content = content.replace(
          `class="ql-size-small"`,
          `style="font-size: 0.75em;"`,
        );
      }
      while (content.includes(`class="ql-size-large"`)) {
        content = content.replace(
          `class="ql-size-large"`,
          `style="font-size: 1.5em;"`,
        );
      }
      while (content.includes(`class="ql-size-huge"`)) {
        content = content.replace(
          `class="ql-size-huge"`,
          `style="font-size: 2.5em;"`,
        );
      }
      const res = await apiMailSendAnnouncement(
        { ...body, content } as MailApiSendAnnouncementRequestBody,
        token,
      );
      if (res?.data.success) {
        message.success('Successfully sent announcement!');
      } else {
        message.error('Error while sending announcement!');
      }
      setLoading(false);
    }
  }

  return (
    <Card bordered={false} title="Send Announcements">
      <Typography.Title level={2}>Send Announcement</Typography.Title>
      <ul style={{ padding: '0 25px 55px' }}>
        <span>You can use:</span>
        <li>
          <strong>{`{{name}}`}</strong> - Injects recipient full name
        </li>
        <li>
          <strong>{`{{email}}`}</strong> - Injects recipient email
        </li>
      </ul>
      <div style={{ marginBottom: 15 }}>
        <SenderSelectorComponent
          onResult={(sender) => {
            setBody({ ...body, account: sender });
          }}
          style={{ marginRight: 15 }}
        />
        <AnnouncementRecipientSelector
          onResult={(recipients) => {
            setBody({
              ...body,
              recipients: recipients.map(({ email, name }) => ({
                email,
                name,
              })),
            });
          }}
          style={{ marginRight: 35 }}
        />
        <div style={{ marginRight: 35, display: 'inline' }}>
          <Checkbox
            onChange={(val) => {
              if (val.target.checked) {
                setScheduleEnabled(true);
              } else {
                setScheduleEnabled(false);
                setBody({
                  ...body,
                  scheduleAt: null,
                });
              }
            }}
          >
            Schedule
          </Checkbox>
          {scheduleEnabled ? <DatePicker showTime onChange={onOk} /> : null}
        </div>
        <Button
          type="primary"
          disabled={!checkIfValid()}
          loading={loading}
          onClick={submit}
        >
          Send
        </Button>
      </div>
      <div style={{ marginBottom: 15 }}>
        <Input
          placeholder="Enter subject"
          type="text"
          onChange={(e) => {
            setBody({ ...body, subject: e.target.value?.trim() });
          }}
        />
      </div>
      <ReactQuill
        theme="snow"
        modules={{
          toolbar: [
            [{ header: '1' }, { header: '2' }, { font: [] }],
            [{ size: [] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [
              { list: 'ordered' },
              { list: 'bullet' },
              { indent: '-1' },
              { indent: '+1' },
            ],
            ['link'],
            ['clean'],
          ],
          clipboard: {
            // toggle to add extra line breaks when pasting HTML:
            matchVisual: false,
          },
        }}
        onChange={(content) => {
          setBody({
            ...body,
            content,
          });
        }}
      />
    </Card>
  );
}
