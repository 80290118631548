import { FormNewFormState, StepsFormState } from '..';
import {
  DesignType,
  InputType,
  ViewType,
} from '../../../services/api/types/steps';

export const RoofingWizardTemplate: {
  formF: FormNewFormState;
  stepsF: StepsFormState;
} = {
  formF: {
    name: 'Example Roofing Form',
    slug: 'example-roofing-form',
    brandColor: '189ED9',
    origin: 'https://example.com/',
    widgetTitle: 'Get estimate for free',
    widgetButtonText: 'Start estimation',
    logoImageId: null as any,
    developmentMode: false,
    requirePhone: true,
    requireAdditionalNotes: true,
    createDefaultPopup: true,
    requireAddress: true,
    linkable: true,
    resultScreenButtonRedirect: null,
    resultScreenRedirect: null,
  },
  stepsF: {
    steps: [
      {
        title: 'What kind of location is this?',
        order: 0,
        isFinalStep: false,
        submitButtonText: 'Continue',
        type: InputType.SINGLE_SELECT,
        designType: DesignType.CARDS,
        viewType: ViewType.GRID,
        nextQuestionIndex: 1,
        dataAnnotation: null,
        options: [
          {
            value: 'House',
            order: 0,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Commercial',
            order: 1,
            imageId: null as any,
            nextQuestionIndex: null,
          },
        ],
      },
      {
        title: 'What type of roofing services are you in need of?',
        order: 1,
        isFinalStep: false,
        submitButtonText: 'Continue',
        type: InputType.SINGLE_SELECT,
        designType: DesignType.CARDS,
        viewType: ViewType.GRID,
        nextQuestionIndex: 2,
        dataAnnotation: null,
        options: [
          {
            value: 'Tile Reset',
            order: 0,
            resultTitle: 'Tile Reset',
            resultDescription:
              'Tile reset is a roofing process that involves removing and replacing damaged or worn out tiles. The cost of tile reset varies depending on the size and complexity of the roof, as well as the type of tiles used.',
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Roof Repair',
            order: 1,
            resultTitle: 'Roof Repair',
            resultDescription:
              'Our estimated pricing for a roof repair is based on the extent of the damage and the materials needed to complete the repairs. Our roofing professionals will inspect your roof to determine the extent of the damage and provide a detailed estimate based on the repairs needed. The estimated price for a roof repair depends on the extent of the damage.',
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'New Roof',
            order: 2,
            resultTitle: 'New Roof',
            resultDescription:
              'For the new roof, we utilize the best and highest quality materials available and install to the greatest standard possible to ensure a long-lasting and safe roof. Our team is dedicated to providing exceptional craftsmanship and attention to detail throughout the installation process. We stand behind our work and strive to deliver a high-quality product that meets or exceeds your expectations. The estimated price for a new roof installation includes all necessary materials, labor, and disposal fees.',
            imageId: null as any,
            nextQuestionIndex: null,
          },
        ],
      },
      {
        title: 'How many stories is your object?',
        order: 2,
        isFinalStep: false,
        submitButtonText: 'Continue',
        type: InputType.SINGLE_SELECT,
        designType: DesignType.CARDS,
        viewType: ViewType.GRID,
        nextQuestionIndex: 3,
        dataAnnotation: null,
        options: [
          {
            value: 'One Story',
            order: 0,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Two Story',
            order: 1,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Three stories or more',
            order: 2,
            imageId: null as any,
            nextQuestionIndex: null,
          },
        ],
      },
      {
        title: 'Are you aware of any leaks or damage to the roof?',
        order: 3,
        isFinalStep: false,
        submitButtonText: 'Continue',
        type: InputType.SINGLE_SELECT,
        designType: DesignType.CARDS,
        viewType: ViewType.GRID,
        nextQuestionIndex: 4,
        dataAnnotation: null,
        options: [
          {
            value: 'Yes',
            order: 0,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'No',
            order: 1,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Unsure',
            order: 2,
            imageId: null as any,
            nextQuestionIndex: null,
          },
        ],
      },
      {
        title: 'What is the approximate size of the roof?',
        order: 4,
        isFinalStep: false,
        submitButtonText: 'Continue',
        type: InputType.SINGLE_SELECT,
        designType: DesignType.CARDS,
        viewType: ViewType.GRID,
        nextQuestionIndex: 5,
        dataAnnotation: null,
        options: [
          {
            value: 'One story residential',
            order: 0,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Two stories residential',
            order: 1,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Commercial building',
            order: 2,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Industrial size building',
            order: 3,
            imageId: null as any,
            nextQuestionIndex: null,
          },
        ],
      },
      {
        title: 'Are there any specific materials you are interested in using?',
        order: 5,
        isFinalStep: false,
        submitButtonText: 'Continue',
        type: InputType.SINGLE_SELECT,
        designType: DesignType.CARDS,
        viewType: ViewType.GRID,
        nextQuestionIndex: 6,
        dataAnnotation: null,
        options: [
          {
            value: 'Asphalt',
            order: 0,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Tile',
            order: 1,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Cedar',
            order: 2,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Other',
            order: 3,
            imageId: null as any,
            nextQuestionIndex: null,
          },
        ],
      },
      {
        title: 'When are you looking to have the work completed on your roof?',
        order: 6,
        isFinalStep: false,
        submitButtonText: 'Continue',
        type: InputType.SINGLE_SELECT,
        designType: DesignType.CARDS,
        viewType: ViewType.GRID,
        nextQuestionIndex: 7,
        dataAnnotation: null,
        options: [
          {
            value: 'As soon as possible',
            order: 0,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: `I'm flexible`,
            order: 1,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Planning and budgeting for future',
            order: 2,
            imageId: null as any,
            nextQuestionIndex: null,
          },
        ],
      },
      {
        title:
          'Alright, you are one step away from your free instant estimate!',
        order: 7,
        isFinalStep: true,
        submitButtonText: 'Submit For An Estimation',
        type: null,
        designType: DesignType.CARDS,
        viewType: ViewType.GRID,
        options: undefined as any,
        nextQuestionIndex: null,
        dataAnnotation: null,
      },
    ],
  },
};
