/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  Button,
  Card,
  Form,
  Input,
  message,
  Select,
  Switch,
  Typography,
} from 'antd';
import Table, { ColumnsType } from 'antd/es/table';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import Image from '../../components/Image';
import { RouterPaths } from '../../router/paths';
import {
  apiStepsDelete,
  apiStepsGet,
  apiStepsUpdate,
} from '../../services/api/steps';
import { ImageEntity } from '../../services/api/types/images';
import { OptionEntity, PriceEntity } from '../../services/api/types/options';
import {
  ALLOWED_INPUT_TYPES,
  ApiStepsGetResponse,
  ApiStepsUpdateRequestBody,
  DesignType,
  INPUT_TYPES_WITH_LABEL,
  INPUT_TYPES_WITH_SECOND_LABEL,
  InputType,
  StepEntity,
  ViewType,
} from '../../services/api/types/steps';
import { selectUserState } from '../../store/selectors/user';
import { ApiFormsGetResponse } from '../../services/api/types/forms';
import { apiFormsGet } from '../../services/api/forms';
import { DefaultOptionType } from 'antd/es/select';
import { LengthConstants } from '../../services/api/types';

export interface StepEditFormState {
  title?: string;
  order?: number;
  submitButtonText?: string;
  isFinalStep?: boolean;
  nextStep: number | string;
  inputType?: InputType;
  designType?: DesignType;
  viewType?: ViewType;
  inputLabel?: string;
  dataAnnotation?: string;
  description?: string | null;
  optional?: boolean;
}

interface DataType extends OptionEntity {
  key: string;
}

function StepEdit() {
  const navigate = useNavigate();
  const { stepId } = useParams();
  const { token } = useSelector(selectUserState);
  const [stepEditForm] = Form.useForm<StepEditFormState>();
  const [responseGetStep, setResponseGetStep] =
    React.useState<ApiStepsGetResponse | null>(null);
  const [responseGetForm, setResponseGetForm] =
    React.useState<ApiFormsGetResponse | null>(null);

  async function loadStepData(tk: string, sid: number) {
    const [response, status] = await apiStepsGet(tk, sid);
    if (status !== 200) {
      message.error({ content: 'No step found!' });
    } else {
      setResponseGetStep(response);
      if (response.data.form?.id) {
        loadFormData(tk, response.data.form.id);
      }
    }
  }

  const stepTypeWatch = Form.useWatch('inputType', stepEditForm);
  const isFinalStep = Form.useWatch('isFinalStep', stepEditForm);

  async function loadFormData(tk: string, fid: number) {
    const [response, status] = await apiFormsGet(tk, fid);
    if (status === 200) {
      setResponseGetForm(response);
    } else {
      message.error({ content: 'Error loading form!' });
      navigate('/');
    }
  }

  React.useEffect(() => {
    if (token && stepId) {
      loadStepData(token, parseInt(stepId, 10));
    }
  }, [stepId, token]);

  const updateStepCallback = React.useCallback(
    async (values: StepEditFormState) => {
      if (token && responseGetStep) {
        const updateStepRequestBody: ApiStepsUpdateRequestBody = {
          stepId: responseGetStep.data.id,
        };
        const dataAnnotationValue = values.dataAnnotation?.length
          ? values.dataAnnotation
          : null;
        let shouldUpdate = false;
        const nextStepParsed: number | null =
          typeof values.nextStep !== 'number' ? null : values.nextStep;
        if (values.title !== responseGetStep.data.title) {
          updateStepRequestBody.title = values.title;
          shouldUpdate = true;
        }
        if (
          parseInt(values.order as unknown as string, 10) !==
          responseGetStep.data.order
        ) {
          updateStepRequestBody.order = parseInt(
            values.order as unknown as string,
            10,
          );
          shouldUpdate = true;
        }
        if (values.submitButtonText !== responseGetStep.data.submitButtonText) {
          updateStepRequestBody.submitButtonText = values.submitButtonText;
          shouldUpdate = true;
        }
        if (values.inputType !== responseGetStep.data.inputs?.[0]?.inputType) {
          updateStepRequestBody.inputType = values.inputType;
          shouldUpdate = true;
        }
        if (
          INPUT_TYPES_WITH_LABEL.includes(values.inputType as any) &&
          values.inputLabel !== responseGetStep.data.inputs?.[0]?.question
        ) {
          updateStepRequestBody.inputLabel = values.inputLabel;
          shouldUpdate = true;
        }
        if (
          values.designType !== responseGetStep.data.inputs?.[0]?.designType
        ) {
          updateStepRequestBody.designType = values.designType;
          shouldUpdate = true;
        }
        if (values.viewType !== responseGetStep.data.inputs?.[0]?.viewType) {
          updateStepRequestBody.viewType = values.viewType;
          shouldUpdate = true;
        }
        if (values.isFinalStep !== responseGetStep.data.isFinalStep) {
          updateStepRequestBody.isFinalStep = values.isFinalStep;
          shouldUpdate = true;
        }
        if (values.optional !== responseGetStep.data.inputs?.[0]?.optional) {
          updateStepRequestBody.optional = values.optional;
          shouldUpdate = true;
        }
        const descriptionValue = values.description?.length
          ? values.description
          : null;
        if (descriptionValue !== responseGetStep.data.description) {
          updateStepRequestBody.description = descriptionValue;
          shouldUpdate = true;
        }
        if (
          responseGetStep.data.inputs?.[0] &&
          dataAnnotationValue !== responseGetStep.data.inputs[0].dataAnnotation
        ) {
          updateStepRequestBody.dataAnnotation = dataAnnotationValue;
          shouldUpdate = true;
        }
        if (nextStepParsed !== (responseGetStep.data.nextStep?.id || null)) {
          updateStepRequestBody.nextStepId = nextStepParsed;
          shouldUpdate = true;
        }

        if (shouldUpdate) {
          const [response, status] = await apiStepsUpdate(
            token,
            updateStepRequestBody,
          );
          if (status === 200) {
            message.success({ content: 'Step updated successfully!' });
            loadStepData(token, response.data.id);
          } else {
            message.error({
              content: (response as any)?.message || 'Failed step update!',
            });
          }
        }
      }
    },
    [token, responseGetStep],
  );

  const columns = React.useMemo<ColumnsType<DataType>>(
    () => [
      {
        key: 'id',
        title: 'ID',
        fixed: true,
        dataIndex: 'id',
        render: (v) => `${v}`,
      },
      {
        key: 'order',
        title: 'Order',
        fixed: true,
        dataIndex: 'order',
        render: (v) => `${v}`,
      },
      {
        key: 'value',
        title: 'Value',
        fixed: true,
        dataIndex: 'value',
        render: (v) => `${v}`,
      },
      {
        key: 'nextStep',
        title: 'Next Step',
        dataIndex: 'nextStep',
        render: (v: StepEntity) =>
          `${
            v
              ? `(${v.id}) ${
                  v.title.length > 20 ? v.title.slice(0, 20) + '...' : v.title
                }`
              : 'No Next Step'
          }`,
      },
      {
        key: 'image',
        title: 'Image',
        dataIndex: 'image',
        render: (v?: ImageEntity) => (v?.id ? <Image id={v.id} /> : 'No Image'),
      },
      {
        key: 'price',
        title: 'Price ID',
        dataIndex: 'price',
        render: (v: PriceEntity) => (v ? `${v.id}` : 'No Result Assigned'),
      },
      {
        key: 'price',
        title: 'Result Title',
        dataIndex: 'price',
        render: (v: PriceEntity) => (v ? `${v.title}` : 'No Result Assigned'),
      },
      {
        key: 'price',
        title: 'Result Description',
        dataIndex: 'price',
        render: (v: PriceEntity) =>
          v ? `${v.description}` : 'No Result Assigned',
      },
      {
        key: 'price',
        title: 'Result Image',
        dataIndex: 'price',
        render: (v: PriceEntity) =>
          v ? (
            v.image ? (
              <Image id={v.image.id} />
            ) : (
              'No Image'
            )
          ) : (
            'No Result Assigned'
          ),
      },
      {
        key: 'price',
        title: 'Result Price Starting From',
        dataIndex: 'price',
        render: (v: PriceEntity) =>
          v ? `${v.minPriceRule}` : 'No Result Assigned',
      },
    ],
    [],
  );

  if (!stepId) {
    message.error('Missing stepId in url!');
    navigate('/');
    return null;
  }

  const options: DataType[] = [];
  if (responseGetStep) {
    responseGetStep.data.inputs?.forEach((inp) => {
      inp.options?.forEach((opt) =>
        options.push({ ...opt, key: opt.id.toString() }),
      );
    });
  }

  return (
    <Card bordered={false} title="Single Step">
      <Typography.Title level={2}>Step Edit</Typography.Title>
      {responseGetStep ? (
        <>
          <Form form={stepEditForm} onFinish={updateStepCallback}>
            <Form.Item
              label="Title"
              name="title"
              rules={[
                {
                  required: true,
                  message: 'Please input step title!',
                  max: 512,
                },
              ]}
              initialValue={responseGetStep.data.title}
            >
              <Input placeholder="Do you need service?" />
            </Form.Item>
            <Form.Item
              label="Description"
              name="description"
              rules={[
                {
                  max: 512,
                },
              ]}
              initialValue={responseGetStep.data.description}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Order"
              name="order"
              rules={[
                {
                  required: true,
                  message: 'Please input step order!',
                },
              ]}
              initialValue={responseGetStep.data.order}
            >
              <Input type="number" />
            </Form.Item>
            <Form.Item
              label="Submit Button Text"
              name="submitButtonText"
              rules={[
                {
                  required: true,
                  message: 'Please input step submit button text!',
                  max: 128,
                },
              ]}
              initialValue={responseGetStep.data.submitButtonText}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label="Step Type"
              name="inputType"
              initialValue={responseGetStep.data.inputs?.[0]?.inputType}
            >
              <Select>
                {ALLOWED_INPUT_TYPES.map((type) => (
                  <Select.Option key={type} value={type}>
                    {type}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="Design Type"
              name="designType"
              initialValue={responseGetStep.data.inputs?.[0]?.designType}
            >
              <Select>
                {Object.values(DesignType).map((designType) => (
                  <Select.Option key={designType} value={designType}>
                    {designType}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              label="View Type"
              name="viewType"
              initialValue={responseGetStep.data.inputs?.[0]?.viewType}
            >
              <Select>
                {Object.values(ViewType).map((viewType) => (
                  <Select.Option key={viewType} value={viewType}>
                    {viewType}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
            {stepTypeWatch && INPUT_TYPES_WITH_LABEL.includes(stepTypeWatch) ? (
              <Form.Item
                label="Input Label"
                name="inputLabel"
                initialValue={responseGetStep.data.inputs?.[0]?.inputLabel}
                rules={[
                  {
                    required: true,
                    message: 'Please input label button text!',
                    max: LengthConstants.INPUT_LABEL,
                  },
                ]}
                required={true}
              >
                <Input />
              </Form.Item>
            ) : null}
            {stepTypeWatch &&
            INPUT_TYPES_WITH_SECOND_LABEL.includes(stepTypeWatch) ? (
              <Form.Item
                label="Input Label Second"
                name="inputLabelSecond"
                initialValue={
                  responseGetStep.data.inputs?.[0]?.inputLabelSecond
                }
                rules={[
                  {
                    required: true,
                    message: 'Please enter second input label!',
                    max: LengthConstants.INPUT_LABEL,
                  },
                ]}
                required={true}
              >
                <Input />
              </Form.Item>
            ) : null}
            {!isFinalStep ? (
              <Form.Item
                label="Data Annotation"
                name="dataAnnotation"
                initialValue={responseGetStep.data.inputs?.[0]?.dataAnnotation}
              >
                <Input maxLength={124} />
              </Form.Item>
            ) : null}
            <Form.Item
              label="Is final step"
              name="isFinalStep"
              valuePropName="checked"
              initialValue={responseGetStep.data.isFinalStep}
            >
              <Switch />
            </Form.Item>
            <Form.Item
              label="Next Step"
              name="nextStep"
              initialValue={responseGetStep.data.nextStep?.id || ''}
            >
              <Select
                options={
                  responseGetForm?.data
                    ? responseGetForm?.data.steps
                        ?.map<DefaultOptionType>((step) => ({
                          value: step.id,
                          label: step.title,
                        }))
                        .concat([{ label: 'No Next Step', value: '' }])
                    : []
                }
              />
            </Form.Item>
            <Form.Item
              label="Optional"
              name="optional"
              valuePropName="checked"
              initialValue={responseGetStep.data.inputs?.[0]?.optional}
            >
              <Switch />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Update Step
              </Button>
            </Form.Item>
          </Form>
          <Button
            type="primary"
            style={{ marginBottom: '45px' }}
            danger
            onClick={async () => {
              if (token) {
                const [, status] = await apiStepsDelete(
                  token,
                  responseGetStep.data.id,
                );
                if (status !== 200) {
                  message.error('Failed to delete step!');
                } else {
                  message.success('Successfully deleted step!');
                  navigate(
                    `${RouterPaths.FORMS}/${responseGetStep.data?.form?.id}`,
                  );
                }
              }
            }}
          >
            Delete Step
          </Button>
        </>
      ) : (
        'Loading'
      )}
      <Typography.Title level={2}>Options</Typography.Title>
      <Table
        dataSource={options?.sort((a, b) => a.order - b.order) || []}
        loading={responseGetStep === null}
        columns={columns}
        pagination={false}
        scroll={{ x: true }}
        onRow={(record) => ({
          onClick: () => {
            navigate(`${RouterPaths.OPTIONS}/${record.id}`);
          },
        })}
      />
      <Button
        style={{ marginTop: '25px' }}
        type="primary"
        onClick={() => {
          navigate(`${RouterPaths.OPTIONS}/create/${responseGetStep?.data.id}`);
        }}
      >
        Add Option
      </Button>
    </Card>
  );
}

export default StepEdit;
