import { Button, Card, Checkbox, Form, message, Typography } from 'antd';
import React, { useEffect } from 'react';
import {
  BillingSettingsDto,
  UpdateBillingSettingsRequest,
} from '../../services/api/types/billing';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/selectors/user';
import {
  apiGetBillingSettings,
  apiUpdateBillingSettings,
} from '../../services/api/billing';
import { useForm } from 'antd/es/form/Form';
import BillingSubscriptionEdit from '../../components/BillingSubscriptionEdit';

interface BillingSettingsFormValues {
  paymentWall?: boolean;
}

function BillingSettingsPage() {
  const { token } = useSelector(selectUserState);
  const [billingSettings, setBillingSettings] =
    React.useState<BillingSettingsDto | null>(null);
  const [form] = useForm<BillingSettingsFormValues>();
  const [loading, setLoading] = React.useState<boolean>(false);

  async function loadBillingSettings() {
    if (token) {
      const [res, status] = await apiGetBillingSettings(token, {});
      if (status === 201 && res.billingSettings) {
        setBillingSettings(res.billingSettings);
        form.resetFields();
        form.setFieldsValue({
          paymentWall: res.billingSettings.paymentWall,
        });
      } else {
        message.error('Failed to load billing settings');
      }
    }
  }

  async function updateBillingSettings(values: BillingSettingsFormValues) {
    if (token && billingSettings) {
      const updateBody: UpdateBillingSettingsRequest = {};

      if (values.paymentWall !== billingSettings?.paymentWall) {
        updateBody.paymentWall = values.paymentWall;
      }

      if (Object.keys(updateBody).length === 0) {
        message.info('No changes to save');
        return;
      }

      setLoading(true);

      const [res, status] = await apiUpdateBillingSettings(token, updateBody);

      if (status === 201 && res.billingSettings) {
        setBillingSettings(res.billingSettings);
        message.success('Billing settings updated');
      } else {
        message.error('Failed to update billing settings');
      }

      setLoading(false);
    }
  }

  useEffect(() => {
    loadBillingSettings();
  }, [token]);

  return (
    <Card title="Billing Settings">
      <Typography.Title level={3}>Actions</Typography.Title>
      <div style={{ display: 'flex', marginBottom: 34 }}>
        <Button
          href="https://app.lemonsqueezy.com/dashboard"
          target="_blank"
          style={{ marginRight: 8 }}
        >
          Lemonsqueezy Dashboard
        </Button>
        <Button href="https://app.lemonsqueezy.com/discounts" target="_blank">
          Lemonsqueezy Discounts
        </Button>
      </div>
      <Typography.Title level={3}>Settings</Typography.Title>
      {billingSettings ? (
        <Form
          form={form}
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 34,
          }}
          onFinish={updateBillingSettings}
          disabled={loading}
        >
          <Form.Item
            name="paymentWall"
            label="Payment wall on registration"
            initialValue={billingSettings?.paymentWall}
            valuePropName="checked"
            style={{ marginBottom: 14 }}
          >
            <Checkbox />
          </Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Save Changes
          </Button>
        </Form>
      ) : null}
      <Typography.Title level={3}>Subscription packages</Typography.Title>
      <BillingSubscriptionEdit />
    </Card>
  );
}

export default BillingSettingsPage;
