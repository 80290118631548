import React from 'react';
import { API_URL } from '../../services/api/constants';

export interface ImageProps {
  id?: number;
  aliasId?: string;
  style?: React.CSSProperties;
  className?: string;
}

function Image({ id, aliasId, style, className = '' }: ImageProps) {
  let url = `${API_URL}/api/images/download`;
  if (id) {
    url += `?imageId=${id}`;
  } else if (aliasId) {
    url += `?id=${aliasId}`;
  }
  return (
    <img
      src={url}
      style={{
        width: '70px',
        objectFit: 'contain',
        ...style,
      }}
      className={className}
    />
  );
}

export default Image;
