export const AVAILABLE_DATE_FORMATS: string[] = [
  'MM/DD/YYYY',
  'MM-DD-YYYY',
  'MM.DD.YYYY',
  'DD/MM/YYYY',
  'DD-MM-YYYY',
  'DD.MM.YYYY',
  'YYYY/MM/DD',
  'YYYY-MM-DD',
  'YYYY.MM.DD',
];
