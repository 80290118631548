import {
  Button,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Tooltip,
  Typography,
} from 'antd';
import React, { useEffect } from 'react';
import { selectUserState } from '../../store/selectors/user';
import { useSelector } from 'react-redux';
import {
  ChangeSubscriptionRequest,
  SubscriptionCycle,
  SubscriptionStatus,
  UserBillingAccountDto,
  UserSubscriptionDto,
} from '../../services/api/types/billing';
import { useForm } from 'antd/es/form/Form';
import {
  apiCancelSubscription,
  apiChangeSubscription,
  apiCreateCheckout,
  apiGetActiveSubscription,
  apiListUserBillingAccounts,
} from '../../services/api/billing';
import { RouterPaths } from '../../router/paths';
import { useNavigate } from 'react-router';
import SubscriptionSelector from '../SubscriptionSelector';
import SubscriptionCycleSelector from '../CycleSelector';
import SubscriptionStatusSelector from '../SubscriptionStatusSelector';
import { DollarOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';

interface UserSubscriptionFormValues {
  subscriptionType: string;
  cycle: SubscriptionCycle;
  priceOverrideCent: number | null;
  status: SubscriptionStatus;
}

interface UserSubscriptionEditProps {
  userId: string;
}

function UserSubscriptionEdit({ userId }: UserSubscriptionEditProps) {
  const navigate = useNavigate();
  const { token } = useSelector(selectUserState);
  const [activeSubscription, setActiveSubscription] =
    React.useState<UserSubscriptionDto | null>(null);
  const [activeDefaultBillingAccount, setActiveDefaultBillingAccount] =
    React.useState<UserBillingAccountDto | null>(null);
  const [cancelModal, cancelModalRender] = Modal.useModal();
  const [checkoutModal, checkoutModalRender] = Modal.useModal();
  const [loadingCheckout, setLoadingCheckout] = React.useState(false);
  const [form] = useForm<UserSubscriptionFormValues>();

  async function loadDefaultBillingAccount() {
    if (token && userId) {
      const [response, status] = await apiListUserBillingAccounts(token, {
        userId,
      });
      if (status === 201 && response) {
        const activeDefaultBillingAccount = response.userBillingAccounts.find(
          (account) => account.defaultAccount && account.active,
        );
        const activeAccount = response.userBillingAccounts.find(
          (account) => account.active,
        );
        setActiveDefaultBillingAccount(
          activeDefaultBillingAccount ?? activeAccount ?? null,
        );
      } else {
        message.error({ content: 'Could not load default billing account!' });
      }
    }
  }

  async function updateSubscriptionType(values: UserSubscriptionFormValues) {
    if (
      token &&
      activeSubscription &&
      userId &&
      activeSubscription.subscription
    ) {
      const updateBody: ChangeSubscriptionRequest = {
        userId,
      };
      if (values.subscriptionType !== activeSubscription.subscription.type) {
        updateBody.subscriptionType = values.subscriptionType;
      }

      if (values.cycle !== activeSubscription.cycle) {
        updateBody.cycle = values.cycle;
        updateBody.subscriptionType = values.subscriptionType;
      }

      const priceOverrideValue = values.priceOverrideCent ?? null;
      if (priceOverrideValue !== activeSubscription.priceOverrideCent) {
        updateBody.priceOverrideCent = priceOverrideValue;
        updateBody.subscriptionType = values.subscriptionType;
      }

      if (values.status !== activeSubscription.status) {
        updateBody.status = values.status;
        updateBody.subscriptionType = values.subscriptionType;
      }

      if (Object.keys(updateBody).length === 1) {
        message.error({ content: 'No changes detected!' });
        return;
      }

      const [response, status] = await apiChangeSubscription(token, updateBody);

      if (status === 201 && response) {
        message.success({ content: 'Subscription updated!' });
        loadUserActiveSubscription();
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } else {
        message.error({ content: 'Failed to update subscription!' });
      }
    }
  }

  async function createCheckout() {
    setLoadingCheckout(true);
    const formValues = form.getFieldsValue();
    if (token && userId && formValues?.subscriptionType && formValues?.cycle) {
      if (formValues.subscriptionType === 'free') {
        message.error({
          content: 'Free subscription cannot be created as checkout!',
        });
        setLoadingCheckout(false);
        return;
      }

      const [response, status] = await apiCreateCheckout(token, {
        userId,
        subscriptionType: formValues.subscriptionType,
        cycle: formValues.cycle,
        priceOverrideCent: formValues.priceOverrideCent ?? undefined,
      });
      if (status === 201 && response) {
        const expireRender = response.checkout?.linkData?.expiresAt
          ? `${dayjs().to(response.checkout?.linkData?.expiresAt)} - ${new Date(
              response.checkout?.linkData?.expiresAt,
            ).toLocaleString()}`
          : 'Unlimited';
        checkoutModal.info({
          width: 800,
          title: 'Checkout link',
          content: (
            <div>
              <Input value={response.checkout?.linkData?.url} />
              <p>Send this to customer so he can pay his subscription. </p>
              <span>
                <strong>Expires at: </strong>
                {expireRender}
              </span>
            </div>
          ),
        });
      } else {
        message.error({ content: 'Failed to create checkout!' });
      }
      setLoadingCheckout(false);
    }
  }

  async function cancelSubscription() {
    if (token && userId) {
      const [response, status] = await apiCancelSubscription(token, {
        userId,
      });
      if (status === 201 && response) {
        message.success({ content: 'Subscription canceled!' });
        loadUserActiveSubscription();
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } else {
        message.error({ content: 'Failed to cancel subscription!' });
      }
    }
  }

  async function loadUserActiveSubscription() {
    if (token && userId) {
      const [activeSubscriptionResponse, activeSubscriptionStatus] =
        await apiGetActiveSubscription(token, { userId });
      await loadDefaultBillingAccount();
      if (
        activeSubscriptionStatus === 201 &&
        activeSubscriptionResponse?.userSubscription
      ) {
        setActiveSubscription(activeSubscriptionResponse.userSubscription);
        form.resetFields();
        form.setFieldsValue({
          subscriptionType:
            activeSubscriptionResponse.userSubscription.subscription?.type,
          cycle: activeSubscriptionResponse.userSubscription.cycle,
          priceOverrideCent:
            activeSubscriptionResponse.userSubscription.priceOverrideCent,
          status: activeSubscriptionResponse.userSubscription.status,
        });
      } else {
        message.error({ content: 'Could not load user subscription!' });
        navigate(RouterPaths.USERS);
      }
    }
  }

  useEffect(() => {
    if (token && userId) {
      loadUserActiveSubscription();
    }
  }, [token, userId]);

  if (!activeSubscription) {
    return null;
  }

  return (
    <div>
      <Typography.Title>User Subscription Edit</Typography.Title>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginBottom: 24,
        }}
      >
        <span style={{ marginBottom: 4 }}>
          <strong style={{ marginRight: 8 }}>Created At:</strong>
          {new Date(activeSubscription.createdAt).toLocaleString()}
        </span>
        <span style={{ marginBottom: 4 }}>
          <strong style={{ marginRight: 8 }}>Ends At:</strong>
          {activeSubscription.endsOn
            ? new Date(activeSubscription.endsOn).toLocaleString()
            : 'Unlimited'}
        </span>
      </div>
      <Form form={form} onFinish={updateSubscriptionType}>
        <div
          style={{
            display: 'flex',
            width: '100%',
            marginBottom: 8,
            flexDirection: 'column',
          }}
        >
          <SubscriptionSelector
            name="subscriptionType"
            defaultValue={activeSubscription.subscription?.type}
            style={{
              width: '100%',
              marginRight: 24,
            }}
          />
          <SubscriptionStatusSelector
            name="status"
            defaultValue={activeSubscription.status}
            style={{
              width: '100%',
              marginRight: 24,
            }}
          />
          <SubscriptionCycleSelector
            name="cycle"
            defaultValue={activeSubscription.cycle}
            style={{
              width: '100%',
              marginRight: 24,
            }}
          />
          <Form.Item
            name="priceOverrideCent"
            label="Price Override (CENTS)"
            initialValue={activeSubscription.priceOverrideCent}
            style={{ marginBottom: 24, width: '100%', marginRight: 44 }}
          >
            <InputNumber controls={false} />
          </Form.Item>
          <Button type="primary" style={{ marginBottom: 8 }} htmlType="submit">
            Update subscription
          </Button>
          <Tooltip title="If user has existing subscription, you must first cancel it (or it should expire) to be able to create new checkout screen. You can create checkout for free package.">
            <Button
              icon={<DollarOutlined />}
              type="primary"
              style={{ marginBottom: 8, width: '100%' }}
              onClick={createCheckout}
              disabled={
                !!(
                  activeDefaultBillingAccount?.externalId &&
                  activeDefaultBillingAccount?.externalSubscriptionId
                )
              }
              loading={loadingCheckout}
            >
              Create checkout
            </Button>
          </Tooltip>
          {checkoutModalRender}
          <Button
            type="primary"
            danger
            onClick={() => {
              cancelModal.confirm({
                title: 'Are you sure you want to cancel the subscription?',
                content: 'This action cannot be undone!',
                onOk: cancelSubscription,
              });
            }}
            style={{ marginBottom: 24 }}
          >
            Cancel Subscription
          </Button>
          {cancelModalRender}
        </div>
      </Form>
    </div>
  );
}

export default UserSubscriptionEdit;
