import {
  Button,
  Card,
  Form,
  Typography,
  message,
  ColorPicker,
  InputNumber,
  Checkbox,
  Select,
} from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  apiFormsGet,
  apiFormsUpdateStyles,
  getOwnerOfTheForm,
} from '../../services/api/forms';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/selectors/user';
import {
  ApiFormsGetResponse,
  ApiFormsUpdateFormStylesRequestBody,
  TapformIframeEmbedStyle,
} from '../../services/api/types/forms';
import ImageInput from '../../components/ImageInput/imageInput';
import { useWatch } from 'antd/es/form/Form';

interface FormStyleFormValue {
  backgroundColor: string;
  buttonBackgroundColor: string;
  buttonTextColor: string;
  buttonBorderRadius: number;
  inputBackgroundColor: string;
  inputTextColor: string;
  titleColor: string;
  logoImage: string;
  disableBranding: boolean;
  'iframeEmbedStyle.width': number;
  'iframeEmbedStyle.height': number;
  'iframeEmbedStyle.widthType': string;
  'iframeEmbedStyle.heightType': string;
}

function FormStyleEdit() {
  const { token } = useSelector(selectUserState);
  const { formId } = useParams();
  const navigate = useNavigate();
  const [saving, setSaving] = React.useState(false);
  const [responseGetForm, setResponseGetForm] =
    React.useState<ApiFormsGetResponse | null>(null);
  const [formStyleForm] = Form.useForm<FormStyleFormValue>();

  const iframeWidthTypeSelected = useWatch('iframeEmbedStyle.width');
  const iframeHeightTypeSelected = useWatch('iframeEmbedStyle.height');

  async function loadFormData(tk: string, fid: number) {
    const [response, status] = await apiFormsGet(tk, fid);
    if (status === 200) {
      setResponseGetForm(response);
    } else {
      message.error({ content: 'Error loading form!' });
      navigate('/');
    }
  }

  React.useEffect(() => {
    if (token && formId) {
      loadFormData(token, parseInt(formId, 10));
    }
  }, [token, formId]);

  const formHandleUpdate = React.useCallback(
    async (values: FormStyleFormValue) => {
      setSaving(true);
      if (
        responseGetForm?.data?.style &&
        token &&
        values &&
        Object.keys(values).length
      ) {
        const updateBody: ApiFormsUpdateFormStylesRequestBody = {
          formId: responseGetForm.data.aliasId,
        };

        const backgroundColor = values.backgroundColor;
        const buttonBackgroundColor = values.buttonBackgroundColor;
        const buttonTextColor = values.buttonTextColor;
        const inputBackgroundColor = values.inputBackgroundColor;
        const inputTextColor = values.inputTextColor;
        const titleColor = values.titleColor;

        if (
          backgroundColor &&
          backgroundColor !== responseGetForm.data.style.backgroundColor
        ) {
          updateBody.backgroundColor = backgroundColor;
        }

        if (
          buttonBackgroundColor &&
          buttonBackgroundColor !==
            responseGetForm.data.style.buttonBackgroundColor
        ) {
          updateBody.buttonBackgroundColor = buttonBackgroundColor;
        }

        if (
          buttonTextColor &&
          buttonTextColor !== responseGetForm.data.style.buttonTextColor
        ) {
          updateBody.buttonTextColor = buttonTextColor;
        }

        if (
          values.buttonBorderRadius &&
          values.buttonBorderRadius !==
            responseGetForm.data.style.buttonBorderRadius
        ) {
          updateBody.buttonBorderRadius = values.buttonBorderRadius;
        }

        if (
          inputBackgroundColor &&
          inputBackgroundColor !==
            responseGetForm.data.style.inputBackgroundColor
        ) {
          updateBody.inputBackgroundColor = inputBackgroundColor;
        }

        if (
          inputTextColor &&
          inputTextColor !== responseGetForm.data.style.inputTextColor
        ) {
          updateBody.inputTextColor = inputTextColor;
        }

        if (
          titleColor &&
          titleColor !== responseGetForm.data.style.titleColor
        ) {
          updateBody.titleColor = titleColor;
        }

        if (
          values.logoImage &&
          values.logoImage !== responseGetForm.data.style.logoImage?.aliasId
        ) {
          updateBody.logoImageId = values.logoImage;
        }

        if (
          values.disableBranding !== responseGetForm.data.style.disableBranding
        ) {
          updateBody.disableBranding = values.disableBranding;
        }

        const iframeEmbedStyleUpdates: Partial<TapformIframeEmbedStyle> = {};
        if (
          values['iframeEmbedStyle.width'] !==
          responseGetForm.data.style.iframeEmbedStyle.width
        ) {
          iframeEmbedStyleUpdates.width = values['iframeEmbedStyle.width'];
        }
        if (
          values['iframeEmbedStyle.height'] !==
          responseGetForm.data.style.iframeEmbedStyle.height
        ) {
          iframeEmbedStyleUpdates.height = values['iframeEmbedStyle.height'];
        }
        if (
          values['iframeEmbedStyle.widthType'] !==
          responseGetForm.data.style.iframeEmbedStyle.widthType
        ) {
          iframeEmbedStyleUpdates.widthType = values[
            'iframeEmbedStyle.widthType'
          ] as any;
        }
        if (
          values['iframeEmbedStyle.heightType'] !==
          responseGetForm.data.style.iframeEmbedStyle.heightType
        ) {
          iframeEmbedStyleUpdates.heightType = values[
            'iframeEmbedStyle.heightType'
          ] as any;
        }

        if (Object.keys(iframeEmbedStyleUpdates).length) {
          updateBody.iframeEmbedStyle = iframeEmbedStyleUpdates;
        }

        if (Object.keys(updateBody).length === 1) {
          message.info('No changes detected!');
          setSaving(false);
          return;
        }

        const [, status] = await apiFormsUpdateStyles(token, updateBody);

        if (status === 201) {
          message.success('Form style updated successfully!');
          loadFormData(token, responseGetForm.data.id);
        } else {
          message.error('Failed to update form style!');
        }
      }
      setSaving(false);
    },
    [token, responseGetForm],
  );

  if (!formId) {
    message.error('Missing formId in url!');
    navigate('/');
    return null;
  }

  return (
    <Card bordered={false} title="Form Style Edit">
      <Typography.Title level={2}>Form Style Edit</Typography.Title>
      {responseGetForm?.data?.style ? (
        <Form
          form={formStyleForm}
          onFinish={formHandleUpdate}
          disabled={saving}
        >
          <ImageInput
            name="logoImage"
            label="Form Logo"
            userId={getOwnerOfTheForm(responseGetForm.data)?.id}
            defaultImageId={responseGetForm.data.style?.logoImage?.aliasId}
            useAliasId
          />
          <Form.Item
            label={'Background color'}
            name={'backgroundColor'}
            getValueFromEvent={(color) => {
              return color.toHex();
            }}
            initialValue={responseGetForm?.data?.style?.backgroundColor}
            labelCol={{ span: 24 }}
          >
            <ColorPicker />
          </Form.Item>
          <Form.Item
            label={'Button background color'}
            name={'buttonBackgroundColor'}
            getValueFromEvent={(color) => {
              return color.toHex();
            }}
            initialValue={responseGetForm?.data?.style?.buttonBackgroundColor}
            labelCol={{ span: 24 }}
          >
            <ColorPicker />
          </Form.Item>
          <Form.Item
            label={'Button text color'}
            name={'buttonTextColor'}
            initialValue={responseGetForm?.data?.style?.buttonTextColor}
            labelCol={{ span: 24 }}
          >
            <ColorPicker />
          </Form.Item>
          <Form.Item
            label={'Button border radius'}
            name={'buttonBorderRadius'}
            initialValue={responseGetForm?.data?.style?.buttonBorderRadius}
            labelCol={{ span: 24 }}
          >
            <InputNumber suffix="px" />
          </Form.Item>
          <Form.Item
            label={'Input background color'}
            name={'inputBackgroundColor'}
            getValueFromEvent={(color) => {
              return color.toHex();
            }}
            initialValue={responseGetForm?.data?.style?.inputBackgroundColor}
            labelCol={{ span: 24 }}
          >
            <ColorPicker />
          </Form.Item>
          <Form.Item
            label={'Input text color'}
            name={'inputTextColor'}
            getValueFromEvent={(color) => {
              return color.toHex();
            }}
            initialValue={responseGetForm?.data?.style?.inputTextColor}
            labelCol={{ span: 24 }}
          >
            <ColorPicker />
          </Form.Item>
          <Form.Item
            label={'Title color'}
            name={'titleColor'}
            getValueFromEvent={(color) => {
              return color.toHex();
            }}
            initialValue={responseGetForm?.data?.style?.titleColor}
            labelCol={{ span: 24 }}
          >
            <ColorPicker />
          </Form.Item>
          <Form.Item
            label={'Disable Branding'}
            name={'disableBranding'}
            valuePropName="checked"
            initialValue={responseGetForm?.data?.style?.disableBranding}
            labelCol={{ span: 24 }}
          >
            <Checkbox />
          </Form.Item>
          <Typography.Title level={4}>
            Embedded iframe settings
          </Typography.Title>
          <div style={{ display: 'flex' }}>
            <Form.Item
              label={'Type'}
              name={'iframeEmbedStyle.widthType'}
              style={{ width: 150, marginRight: 8 }}
              initialValue={
                responseGetForm?.data?.style?.iframeEmbedStyle?.widthType ?? '%'
              }
            >
              <Select>
                <Select.Option value="%">%</Select.Option>
                <Select.Option value="px">px</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={'Width'}
              name={'iframeEmbedStyle.width'}
              initialValue={
                responseGetForm?.data?.style?.iframeEmbedStyle?.width ?? 100
              }
            >
              <InputNumber suffix={iframeWidthTypeSelected} />
            </Form.Item>
          </div>
          <div style={{ display: 'flex', marginBottom: 24 }}>
            <Form.Item
              label={'Type'}
              style={{ width: 150, marginRight: 8 }}
              name={'iframeEmbedStyle.heightType'}
              initialValue={
                responseGetForm?.data?.style?.iframeEmbedStyle?.heightType ??
                'auto'
              }
            >
              <Select>
                <Select.Option value="%">%</Select.Option>
                <Select.Option value="px">px</Select.Option>
                <Select.Option value="auto">auto</Select.Option>
              </Select>
            </Form.Item>
            <Form.Item
              label={'Height'}
              name={'iframeEmbedStyle.height'}
              initialValue={
                responseGetForm?.data?.style?.iframeEmbedStyle?.height ?? 100
              }
            >
              <InputNumber
                suffix={iframeHeightTypeSelected}
                disabled={iframeHeightTypeSelected === 'auto'}
              />
            </Form.Item>
          </div>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save Changes
            </Button>
          </Form.Item>
        </Form>
      ) : null}
    </Card>
  );
}

export default FormStyleEdit;
