import { Languages, OpenAIModels } from '.';

export interface AiCreateFormResponse {
  questions: AiCreateFormResponseQuestion[];
  resultDescription: string;
  resultTitle: string;
  lastStepTitle: string;
  submitButtonText: string;
  lastStepButtonText: string;
  version: AIFormVersion;
}

export interface AiCreateFormResponseQuestion {
  question: string;
  type: string;
  options?: AiCreateFormResponseQuestionOption[];
  placeholder?: string;
  questionId?: number | null;
  nextQuestionId?: number | null;
}

export interface AiCreateFormResponseQuestionOption {
  value: string;
  nextQuestionId?: number | null;
  optionId?: number | null;
}

export enum OpenAIFormRequestLevel {
  EASY = 'easy',
  DETAILED = 'detailed',
}

export interface AiCreateFormRequestBody {
  business: string;
  language?: Languages;
  model?: OpenAIModels;
  level?: OpenAIFormRequestLevel;
  maxNumberOfOptions?: number;
  necessaryQuestions?: string[];
  services?: string[];
}

export enum AIFormVersion {
  V1 = '1',
  V2 = '2',
  V3 = '3',
  V4 = '4',
}

export interface AiListCreatedFormsResponseForm {
  name: string;
  data: AiCreateFormResponse;
  version: AIFormVersion;
}

export interface AiListCreatedFormsResponse {
  forms: AiListCreatedFormsResponseForm[];
}
