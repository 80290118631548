import {
  Button,
  DatePicker,
  Form,
  InputNumber,
  message,
  Table,
  TableProps,
  Typography,
} from 'antd';
import React, { useEffect } from 'react';
import {
  ResetFeatureUsageRequest,
  UserAvailableUsageDto,
} from '../../services/api/types/feature-limits';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/selectors/user';
import {
  apiGetUserFeatureUsage,
  apiResetFeatureUsage,
} from '../../services/api/feature-limits';
import { useForm } from 'antd/es/form/Form';
import dayjs, { Dayjs } from 'dayjs';

interface FeatureUsageEditTableProps {
  userId: string;
}

interface FeatureUsageTableRow extends UserAvailableUsageDto {
  key: string;
}

// eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
interface FeatureUsageEditFormValues {
  [key: string]: number | Dayjs;
}

function FeatureUsageEditTable({ userId }: FeatureUsageEditTableProps) {
  const { token } = useSelector(selectUserState);
  const [loading, setLoading] = React.useState(false);
  const [loadedUsages, setLoadedUsages] = React.useState<
    UserAvailableUsageDto[] | null
  >(null);
  const [form] = useForm<FeatureUsageEditFormValues>();

  async function loadUsages() {
    if (token && userId) {
      const [res, status] = await apiGetUserFeatureUsage(token, { userId });

      if (status === 201 && res) {
        setLoadedUsages(res.userAvailableUsages);
        form.resetFields();
        form.setFieldsValue(
          res.userAvailableUsages.reduce<FeatureUsageEditFormValues>(
            (acc, usage) => {
              acc[`${usage.featureUsage.featureType}__usage`] =
                usage.featureUsage.usage;
              acc[`${usage.featureUsage.featureType}__lastReset`] = dayjs(
                usage.featureUsage.lastReset,
              );
              return acc;
            },
            {},
          ),
        );
      } else {
        message.error({ content: 'Failed to load feature usage!' });
      }
    }
  }

  async function resetUserFeatureUsage(values: FeatureUsageEditFormValues) {
    setLoading(true);
    try {
      if (token && userId) {
        const updateBodies: ResetFeatureUsageRequest[] = [];

        Object.entries(values).forEach(([key, value]) => {
          const [featureType, field] = key.split('__');
          const foundUsage = loadedUsages?.find(
            (usage) => usage.featureUsage.featureType === featureType,
          );
          if (!foundUsage) {
            return;
          }
          if (
            field === 'usage' &&
            typeof value === 'number' &&
            foundUsage.featureUsage.usage !== value
          ) {
            const existingBody = updateBodies.find(
              (b) => b.featureType === featureType,
            );
            if (existingBody) {
              existingBody.usage = value;
            } else {
              updateBodies.push({
                userId,
                featureType,
                usage: value,
              });
            }
          } else if (field === 'lastReset' && value instanceof dayjs) {
            const existingBody = updateBodies.find(
              (b) => b.featureType === featureType,
            );
            const dateString = (value as any).toDate().toISOString();
            if (existingBody) {
              existingBody.lastReset = (value as any).toDate().toISOString();
            } else if (foundUsage.featureUsage.lastReset !== dateString) {
              updateBodies.push({
                userId,
                featureType,
                lastReset: dateString,
              });
            }
          }
        });

        if (!updateBodies.length) {
          message.info({ content: 'No changes to save!' });
          setLoading(false);
          return;
        }

        const responses = await Promise.all(
          updateBodies.map((ub) => apiResetFeatureUsage(token, ub)),
        );

        if (responses.every(([_, status]) => status === 201)) {
          message.success({ content: 'Feature usage reset!' });
        } else {
          message.error({ content: 'Failed to reset feature usage!' });
        }
        await loadUsages();
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }

  useEffect(() => {
    loadUsages();
  }, [token, userId]);

  const columns: TableProps<UserAvailableUsageDto>['columns'] = [
    {
      title: 'Feature',
      key: 'featureType',
      dataIndex: 'featureUsage',
      render: (_, record) => record.featureUsage.featureType,
    },
    {
      title: 'Available',
      key: 'featureType',
      dataIndex: 'featureUsage',
      render: (_, record) => (
        <>
          <strong>{record.availableUsage}</strong>
          {` - (${record.featureUsage.usage} / ${
            record.featureLimit.limit === null
              ? 'Unlimited'
              : record.featureLimit.limit
          })`}
        </>
      ),
    },
    {
      title: 'Used',
      key: 'availableUsage',
      render: (_, record) => {
        return (
          <Form.Item
            name={`${record.featureUsage.featureType}__usage`}
            initialValue={record.availableUsage}
            style={{ margin: 0 }}
          >
            <InputNumber controls={false} />
          </Form.Item>
        );
      },
    },
    {
      title: 'Last Reset',
      key: 'featureUsage.lastReset',
      render: (_, record) => {
        return (
          <Form.Item
            name={`${record.featureUsage.featureType}__lastReset`}
            initialValue={dayjs(record.featureUsage.lastReset)}
            style={{ margin: 0 }}
          >
            <DatePicker showTime />
          </Form.Item>
        );
      },
    },
  ];

  const dataSource: FeatureUsageTableRow[] =
    loadedUsages?.map((usage) => ({
      ...usage,
      key: usage.featureUsage.featureType,
    })) ?? ([] as FeatureUsageTableRow[]);

  return (
    <div>
      <Form form={form} onFinish={resetUserFeatureUsage} disabled={loading}>
        <div
          style={{
            marginBottom: '8px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <Typography.Title level={3}>Edit feature usages</Typography.Title>
          <Button type="primary" htmlType="submit">
            Save Changes
          </Button>
        </div>
        <Table
          size="small"
          loading={!loadedUsages || loading}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
        />
      </Form>
    </div>
  );
}

export default FeatureUsageEditTable;
