import { API_URL } from './constants';
import {
  DeleteUserFeatureLimitRequest,
  DeleteUserFeatureLimitResponse,
  GetFeatureLimitsRequest,
  GetFeatureLimitsResponse,
  GetUserFeatureUsageRequest,
  GetUserFeatureUsageResponse,
  ResetFeatureUsageRequest,
  ResetFeatureUsageResponse,
  UpdateFeatureLimitRequest,
  UpdateFeatureLimitResponse,
} from './types/feature-limits';

export async function apiGetFeatureLimits(
  token: string,
  body: GetFeatureLimitsRequest,
): Promise<[GetFeatureLimitsResponse, number]> {
  const url = new URL(`${API_URL}/admin/feature-limits/get-feature-limits`)
    .href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [(await response.json()) as GetFeatureLimitsResponse, response.status];
}

export async function apiUpdateFeatureLimits(
  token: string,
  body: UpdateFeatureLimitRequest,
): Promise<[UpdateFeatureLimitResponse, number]> {
  const url = new URL(`${API_URL}/admin/feature-limits/update-feature-limit`)
    .href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [
    (await response.json()) as UpdateFeatureLimitResponse,
    response.status,
  ];
}

export async function apiGetUserFeatureUsage(
  token: string,
  body: GetUserFeatureUsageRequest,
): Promise<[GetUserFeatureUsageResponse, number]> {
  const url = new URL(`${API_URL}/admin/feature-limits/get-user-feature-usage`)
    .href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [
    (await response.json()) as GetUserFeatureUsageResponse,
    response.status,
  ];
}

export async function apiResetFeatureUsage(
  token: string,
  body: ResetFeatureUsageRequest,
): Promise<[ResetFeatureUsageResponse, number]> {
  const url = new URL(`${API_URL}/admin/feature-limits/reset-feature-usage`)
    .href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [
    (await response.json()) as ResetFeatureUsageResponse,
    response.status,
  ];
}

export async function apiDeleteUserFeatureLimit(
  token: string,
  body: DeleteUserFeatureLimitRequest,
): Promise<[DeleteUserFeatureLimitResponse, number]> {
  const url = new URL(
    `${API_URL}/admin/feature-limits/delete-user-feature-limit`,
  ).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [
    (await response.json()) as DeleteUserFeatureLimitResponse,
    response.status,
  ];
}
