import { Card, Typography } from 'antd';
import SubscriptionSelector from '../../components/SubscriptionSelector';
import { SubscriptionDto } from '../../services/api/types/billing';
import React from 'react';
import FeatureLimitsEdit from '../../components/FeatureLimitsEdit';
import UserSelectorComponent from '../../components/UserSelector';
import { useNavigate } from 'react-router';
import { RouterPaths } from '../../router/paths';

function FeatureLimitsEditPage() {
  const [selectedSubscriptionType, setSelectedSubscriptionType] =
    React.useState<SubscriptionDto | null>(null);
  const navigate = useNavigate();

  return (
    <Card title="Feature Limits">
      <Typography.Title level={2}>Feature Limits</Typography.Title>
      <div style={{ marginBottom: 24 }}>
        <SubscriptionSelector
          name="subscriptionType"
          value={selectedSubscriptionType?.type}
          onChange={(type, subscription) => {
            setSelectedSubscriptionType(subscription);
          }}
        />
        <UserSelectorComponent
          onResult={(userId) => {
            navigate(`${RouterPaths.USERS}/${userId}/feature-limits`);
          }}
        />
      </div>
      <div>
        {selectedSubscriptionType ? (
          <FeatureLimitsEdit subscription={selectedSubscriptionType} />
        ) : null}
      </div>
    </Card>
  );
}

export default FeatureLimitsEditPage;
