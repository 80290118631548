import {
  Button,
  Checkbox,
  Form,
  InputNumber,
  message,
  Table,
  TableProps,
  Tooltip,
  Typography,
} from 'antd';
import { useForm } from 'antd/es/form/Form';
import React, { useEffect } from 'react';
import {
  FeatureLimitDto,
  UpdateFeatureLimitRequest,
} from '../../services/api/types/feature-limits';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/selectors/user';
import {
  apiDeleteUserFeatureLimit,
  apiGetFeatureLimits,
  apiUpdateFeatureLimits,
} from '../../services/api/feature-limits';
import { SubscriptionDto } from '../../services/api/types/billing';
import { useNavigate } from 'react-router';
import { RouterPaths } from '../../router/paths';

interface FeatureLimitRow extends FeatureLimitDto {
  key: string;
}

// eslint-disable-next-line @typescript-eslint/consistent-indexed-object-style
interface FeatureLimitFormValues {
  [featureType: string]: number | null;
}

interface FeatureLimitsEditProps {
  subscription?: SubscriptionDto;
  userId?: string;
}

function FeatureLimitsEdit({ subscription, userId }: FeatureLimitsEditProps) {
  const { token } = useSelector(selectUserState);
  const [saving, setSaving] = React.useState(false);
  const [form] = useForm<FeatureLimitFormValues>();
  const navigate = useNavigate();

  const [loadedFeatureLimits, setLoadedFeatureLimits] = React.useState<
    FeatureLimitDto[] | null
  >(null);

  async function deleteUserFeatureLimit(featureType: string) {
    if (token && featureType && userId) {
      const [res, status] = await apiDeleteUserFeatureLimit(token, {
        featureType,
        userId,
      });

      if (status === 201 && res.featureLimit) {
        message.success({ content: 'User custom limit removed!' });
        await loadFeatureLimits(token);
      } else {
        message.error({ content: 'Failed to remove user custom limit!' });
      }
    }
  }

  const dataSource: FeatureLimitRow[] =
    loadedFeatureLimits?.map((fl) => ({
      ...fl,
      key: fl.id,
    })) || ([] as FeatureLimitRow[]);

  const columns: TableProps<FeatureLimitRow>['columns'] = [
    {
      title: 'Feature',
      dataIndex: 'featureType',
      key: 'featureType',
      filterMode: 'menu',
      filterMultiple: true,
      filterSearch: true,
      onFilter: (value, record) =>
        record.featureType.indexOf(value as string) === 0,
      filters:
        (Array.from(
          new Set(loadedFeatureLimits?.map((fl) => fl.featureType)),
        ).map((lt) => ({
          text: lt,
          value: lt,
        })) as any) || [],
    },
    {
      title: 'Limit Type',
      dataIndex: 'limitType',
      key: 'limitType',
      filterMode: 'menu',
      filterMultiple: true,
      filterSearch: true,
      onFilter: (value, record) =>
        record.limitType.indexOf(value as string) === 0,
      filters:
        (Array.from(
          new Set(loadedFeatureLimits?.map((fl) => fl.limitType)),
        ).map((lt) => ({
          text: lt,
          value: lt,
        })) as any) || [],
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Limit',
      dataIndex: 'limit',
      key: 'limit',
      render: (_, record) => (
        <div style={{ display: 'flex' }}>
          <Form.Item
            name={record.featureType}
            initialValue={record.limit}
            style={{ marginBottom: 0, marginRight: 8 }}
          >
            <InputNumber controls={false} />
          </Form.Item>
          <Form.Item
            name={`${record.featureType}__unlimited`}
            initialValue={record.limit === null}
            style={{ marginBottom: 0 }}
            valuePropName="checked"
          >
            <Checkbox>Unlimited</Checkbox>
          </Form.Item>
        </div>
      ),
    },
    {
      title: 'Actions',
      dataIndex: 'id',
      key: 'actions',
      render: (_, record) => {
        if (!record.user) {
          return null;
        }
        return (
          <Tooltip title="You have put a custom limit for this feature on this user. You can remove it so user limit is tracked with his subscription">
            <Button
              danger
              onClick={() => deleteUserFeatureLimit(record.featureType)}
            >
              Remove user custom limit
            </Button>
          </Tooltip>
        );
      },
    },
  ];

  useEffect(() => {
    if (token) {
      loadFeatureLimits(token);
    }
  }, [token, subscription, userId]);

  async function loadFeatureLimits(token: string) {
    const [response, status] = await apiGetFeatureLimits(token, {
      billingSubscriptionId: subscription?.id ?? undefined,
      userId: userId ?? undefined,
    });
    if (status === 201 && response) {
      setLoadedFeatureLimits(response.featureLimits);
      setTimeout(() => {
        form.resetFields();
      }, 0);
    } else {
      message.error({ content: 'Failed to load feature limits!' });
      navigate(RouterPaths.USERS);
    }
  }

  async function onSaveChanges(values: FeatureLimitFormValues) {
    if ((subscription || userId) && token && loadedFeatureLimits?.length) {
      const updateBody: UpdateFeatureLimitRequest = {
        subscriptionType: subscription?.type ?? undefined,
        userId: userId ?? undefined,
        data: [],
      };

      loadedFeatureLimits.forEach((fl) => {
        const value = values[fl.featureType];
        const isUnlimited = values[`${fl.featureType}__unlimited`];
        if (isUnlimited && fl.limit !== null) {
          updateBody.data.push({
            featureType: fl.featureType,
            limit: null,
          });
        } else if (!isUnlimited && value !== null && value !== fl.limit) {
          updateBody.data.push({
            featureType: fl.featureType,
            limit: value,
          });
        }
      });

      if (updateBody.data.length) {
        setSaving(true);
        const [response, status] = await apiUpdateFeatureLimits(
          token,
          updateBody,
        );
        if (status === 201 && response) {
          message.success({ content: 'Feature limits updated!' });
          await loadFeatureLimits(token);
        } else {
          message.error({ content: 'Failed to update feature limits!' });
        }
        setSaving(false);
      }
    } else {
      message.error({ content: 'Please select a subscription type!' });
    }
  }

  return (
    <Form form={form} onFinish={onSaveChanges} disabled={saving}>
      <div
        style={{
          display: 'flex',
          width: '100%',
          justifyContent: 'space-between',
          marginBottom: 16,
        }}
      >
        <Typography.Title level={3}>Edit feature limits</Typography.Title>
        <Button type="primary" htmlType="submit">
          Save Changes
        </Button>
      </div>
      {loadedFeatureLimits === null ? null : (
        <Table
          key={userId ?? subscription?.id}
          loading={!loadedFeatureLimits || saving}
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          size="small"
        />
      )}
    </Form>
  );
}

export default FeatureLimitsEdit;
