/* eslint-disable symbol-description */
import React from 'react';
import { Card, message, Typography } from 'antd';
import { ApiUsersGetResponse } from '../../services/api/types/users';
import { useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/selectors/user';
import { apiUsersGet } from '../../services/api/users';
import { RouterPaths } from '../../router/paths';
import InvoicesTable from '../../components/InvoicesTable';
import UserBillingAccountsView from '../../components/UserBillingAccountsView';
import UserSubscriptionEdit from '../../components/UserSubscriptionEdit';

function UserBillingPage() {
  const navigate = useNavigate();
  const { userId } = useParams();
  const { token } = useSelector(selectUserState);
  const [loadedUser, setLoadedUser] =
    React.useState<ApiUsersGetResponse | null>(null);

  async function loadUser(tk: string, uid: number) {
    const [response, status] = await apiUsersGet(uid, tk);
    if (status === 200 && response) {
      setLoadedUser(response);
    } else {
      message.error({ content: 'User not found!' });
      navigate(RouterPaths.USERS);
    }
  }

  React.useEffect(() => {
    if (userId && token) {
      loadUser(token, parseInt(userId, 10));
    }
  }, [userId, token]);

  if (!userId) {
    navigate(RouterPaths.USERS);
    return null;
  }

  return (
    <Card bordered={false} title="User Billing" loading={!loadedUser?.id}>
      {!loadedUser?.id ? null : (
        <div>
          <UserSubscriptionEdit userId={loadedUser.aliasId} />
          <Typography.Title level={3}>BillingAccounts</Typography.Title>
          <UserBillingAccountsView userId={loadedUser.aliasId} />
          <Typography.Title level={3}>Invoices</Typography.Title>
          <InvoicesTable userId={loadedUser.aliasId} />
        </div>
      )}
    </Card>
  );
}

export default UserBillingPage;
