import { Form, FormInstance, Select } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { SubscriptionStatus } from '../../services/api/types/billing';
import React from 'react';

interface SubscriptionStatusSelectorProps {
  name?: NamePath;
  label?: string;
  defaultValue?: SubscriptionStatus;
  form?: FormInstance;
  required?: boolean;
  onChange?: (value: SubscriptionStatus) => void;
  value?: SubscriptionStatus;
  style?: React.CSSProperties;
  disabled?: boolean;
}

function SubscriptionStatusSelector({
  name,
  label = 'Subscription status',
  defaultValue,
  form,
  required = true,
  value,
  onChange,
  style,
  disabled = false,
}: SubscriptionStatusSelectorProps) {
  if (onChange) {
    return (
      <Form.Item
        name={name}
        label={label}
        initialValue={defaultValue}
        style={style}
      >
        <Select
          value={value}
          onChange={(type) => {
            onChange(type);
          }}
          disabled={disabled}
        >
          {Object.values(SubscriptionStatus).map((cycle) => (
            <Select.Option key={cycle} value={cycle}>
              {cycle}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  }

  return (
    <Form.Item
      name={name}
      label={label}
      initialValue={defaultValue}
      style={style}
    >
      <Select disabled={disabled}>
        {Object.values(SubscriptionStatus).map((cycle) => (
          <Select.Option key={cycle} value={cycle}>
            {cycle}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default SubscriptionStatusSelector;
