/* eslint-disable @typescript-eslint/consistent-indexed-object-style */
import React, { useEffect, useState } from 'react';
import {
  UpdateUserBillingAccountRequest,
  UserBillingAccountDto,
} from '../../services/api/types/billing';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/selectors/user';
import {
  apiListUserBillingAccounts,
  apiSyncUserSubscriptionWithBillingAccount,
  apiUpdateUserBillingAccount,
} from '../../services/api/billing';
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  Table,
  TableProps,
  Tag,
} from 'antd';

interface UserBillingAccountsViewProps {
  userId: string;
}

interface UserBillingAccountRow extends UserBillingAccountDto {
  key: string;
}

interface UserBillingAccountFormValues {
  [key: string]: string | null;
}

function UserBillingAccountsView({ userId }: UserBillingAccountsViewProps) {
  const { token } = useSelector(selectUserState);
  const [loadedUserBillingAccounts, setLoadedUserBillingAccounts] = useState<
    UserBillingAccountDto[] | null
  >(null);
  const [syncModal, syncModalRender] = Modal.useModal();
  const [form] = Form.useForm<UserBillingAccountFormValues>();
  const [saving, setSaving] = useState(false);

  async function handleSaving(values: UserBillingAccountFormValues) {
    if (token && userId && loadedUserBillingAccounts) {
      setSaving(true);
      const bodies: UpdateUserBillingAccountRequest[] =
        loadedUserBillingAccounts.map((lb) => ({
          userBillingAccountId: lb.id,
        }));

      for (const key in values) {
        const [id, field] = key.split('__');
        const body = bodies.find((b) => b.userBillingAccountId === id);
        const existingAccountValue = (
          loadedUserBillingAccounts.find((uba) => uba.id === id) as any
        )?.[field];
        const valueToUse = values[key] ?? null;
        if (body && valueToUse !== existingAccountValue) {
          (body as any)[field] = valueToUse;
        }
      }

      const bodiesForUpdate = bodies.filter((b) => Object.keys(b).length > 1);

      if (!bodiesForUpdate.length) {
        message.info({ content: 'No changes to save!' });
        setSaving(false);
        return;
      }

      const promises = bodiesForUpdate.map((body) =>
        apiUpdateUserBillingAccount(token, body),
      );

      const responses = await Promise.all(promises);

      const failed = responses.find(([_, status]) => status !== 201);
      if (failed) {
        message.error({ content: 'Failed to save changes!' });
      } else {
        message.success({ content: 'Changes saved!' });
        loadUserBillingAccounts();
        setTimeout(() => {
          window.location.reload();
        }, 500);
      }

      setSaving(false);
    }
  }

  async function syncUserWithBillingAccont() {
    if (token && userId) {
      setSaving(true);
      const [res, status] = await apiSyncUserSubscriptionWithBillingAccount(
        token,
        { userId },
      );

      if (status === 201 && res) {
        message.success({ content: 'User subscription synced!' });
        loadUserBillingAccounts();
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } else {
        message.error({ content: 'Failed to sync user subscription!' });
      }

      setSaving(false);
    }
  }

  const columns: TableProps<UserBillingAccountDto>['columns'] = [
    {
      title: 'Provider',
      key: 'provider',
      dataIndex: 'provider',
    },
    {
      title: 'Active',
      key: 'active',
      dataIndex: 'active',
      render: (active: boolean) =>
        active ? (
          <Tag color="green">Active</Tag>
        ) : (
          <Tag color="red">Inactive</Tag>
        ),
    },
    {
      title: 'External ID',
      key: 'externalId',
      dataIndex: 'externalId',
      render: (_, record) => (
        <Form.Item
          name={`${record.id}__externalId`}
          initialValue={record.externalId}
          style={{ margin: 0 }}
        >
          <Input />
        </Form.Item>
      ),
    },
    {
      title: 'External Subscription ID',
      key: 'externalSubscriptionId',
      dataIndex: 'externalSubscriptionId',
      render: (_, record) => (
        <Form.Item
          name={`${record.id}__externalSubscriptionId`}
          initialValue={record.externalSubscriptionId}
          style={{ margin: 0 }}
        >
          <Input />
        </Form.Item>
      ),
    },
    {
      title: 'Default Account',
      key: 'defaultAccount',
      dataIndex: 'defaultAccount',
      render: (defaultAccount: boolean) =>
        defaultAccount ? (
          <Tag color="green">Default</Tag>
        ) : (
          <Tag color="gray">Not Default</Tag>
        ),
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'id',
      render: (_, record) => {
        if (
          record.provider === 'lemon-squeezy' &&
          record.externalSubscriptionId
        ) {
          return (
            <>
              <Button
                target="_blank"
                href={`https://app.lemonsqueezy.com/subscriptions/${record.externalSubscriptionId}`}
              >
                View
              </Button>
            </>
          );
        }
      },
    },
  ];

  const datasource: UserBillingAccountRow[] =
    loadedUserBillingAccounts?.map((uba) => ({
      ...uba,
      key: uba.id,
    })) || [];

  async function loadUserBillingAccounts() {
    if (token && userId) {
      const [res, status] = await apiListUserBillingAccounts(token, { userId });
      if (status === 201 && res) {
        setLoadedUserBillingAccounts(res.userBillingAccounts);
      } else {
        message.error({ content: 'Failed to load user billing accounts!' });
      }
    }
  }

  useEffect(() => {
    loadUserBillingAccounts();
  }, [userId]);

  return (
    <Form form={form} disabled={saving} onFinish={handleSaving}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          width: '100%',
          marginBottom: 16,
        }}
      >
        <Button
          style={{ marginRight: 8 }}
          target="_blank"
          onClick={() => {
            syncModal.confirm({
              title: 'Sync Subscription with provider',
              content: (
                <p>
                  Are you sure you want to sync the subscription? This will pull
                  latest data from payment provider and adjust user subscription
                </p>
              ),
              onOk: syncUserWithBillingAccont,
            });
          }}
        >
          Sync data with provider
        </Button>
        <Button type="primary" htmlType="submit">
          Save changes
        </Button>
        {syncModalRender}
      </div>
      <Table
        columns={columns}
        dataSource={datasource}
        loading={!loadedUserBillingAccounts}
      />
    </Form>
  );
}

export default UserBillingAccountsView;
