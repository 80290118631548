import {
  Button,
  Card,
  Form,
  Typography,
  message,
  ColorPicker,
  Input,
  InputNumber,
  Checkbox,
  Select,
} from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  apiFormsGet,
  apiFormsUpdateWidget,
  getOwnerOfTheForm,
} from '../../services/api/forms';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/selectors/user';
import {
  ApiFormsGetResponse,
  ApiFormsUpdateFormWidgetRequestBody,
  FormWidgetTheme,
} from '../../services/api/types/forms';
import ImageInput from '../../components/ImageInput/imageInput';
import { LengthConstants } from '../../services/api/types';

interface FormWidgetFormValue {
  widgetTitle: string;
  widgetButtonText: string;
  widgetButtonBackgroundColor: string | null;
  widgetButtonTextColor: string | null;
  widgetLabel: string;
  defaultVisible: boolean;
  offsetBottom: number;
  hideOnBottom: boolean;
  hideShowOnScroll: boolean;
  widgetTheme: FormWidgetTheme;
  imageId: string;
}

function FormWidgetEdit() {
  const { token } = useSelector(selectUserState);
  const { formId } = useParams();
  const navigate = useNavigate();
  const [saving, setSaving] = React.useState(false);
  const [responseGetForm, setResponseGetForm] =
    React.useState<ApiFormsGetResponse | null>(null);
  const [formStyleForm] = Form.useForm<FormWidgetFormValue>();

  async function loadFormData(tk: string, fid: number) {
    const [response, status] = await apiFormsGet(tk, fid);
    if (status === 200) {
      setResponseGetForm(response);
    } else {
      message.error({ content: 'Error loading form!' });
      navigate('/');
    }
  }

  React.useEffect(() => {
    if (token && formId) {
      loadFormData(token, parseInt(formId, 10));
    }
  }, [token, formId]);

  const formHandleUpdate = React.useCallback(
    async (values: FormWidgetFormValue) => {
      setSaving(true);
      if (
        responseGetForm?.data?.style &&
        token &&
        values &&
        Object.keys(values).length
      ) {
        const updateBody: ApiFormsUpdateFormWidgetRequestBody = {
          formId: responseGetForm.data.aliasId,
        };

        if (values.widgetTitle !== responseGetForm.data.widget?.widgetTitle) {
          updateBody.widgetTitle = values.widgetTitle;
        }

        if (
          values.widgetButtonText !==
          responseGetForm.data.widget?.widgetButtonText
        ) {
          updateBody.widgetButtonText = values.widgetButtonText;
        }

        if (
          values.widgetButtonBackgroundColor !==
          responseGetForm.data.widget?.widgetButtonBackgroundColor
        ) {
          updateBody.widgetButtonBackgroundColor =
            values.widgetButtonBackgroundColor;
        }

        if (
          values.widgetButtonTextColor !==
          responseGetForm.data.widget?.widgetButtonTextColor
        ) {
          updateBody.widgetButtonTextColor = values.widgetButtonTextColor;
        }

        if (values.widgetLabel !== responseGetForm.data.widget?.widgetLabel) {
          updateBody.widgetLabel = values.widgetLabel;
        }

        if (
          values.defaultVisible !== responseGetForm.data.widget?.defaultVisible
        ) {
          updateBody.defaultVisible = values.defaultVisible;
        }

        if (values.offsetBottom !== responseGetForm.data.widget?.offsetBottom) {
          updateBody.offsetBottom = values.offsetBottom;
        }

        if (values.hideOnBottom !== responseGetForm.data.widget?.hideOnBottom) {
          updateBody.hideOnBottom = values.hideOnBottom;
        }

        if (
          values.hideShowOnScroll !==
          responseGetForm.data.widget?.hideShowOnScroll
        ) {
          updateBody.hideShowOnScroll = values.hideShowOnScroll;
        }

        if (values.widgetTheme !== responseGetForm.data.widget?.widgetTheme) {
          updateBody.widgetTheme = values.widgetTheme;
        }

        const currentImageId =
          responseGetForm.data.widget?.image?.aliasId ?? null;
        if (values.imageId !== currentImageId) {
          updateBody.imageId = values.imageId;
        }

        if (Object.keys(updateBody).length === 1) {
          message.info('No changes detected!');
          setSaving(false);
          return;
        }

        const [, status] = await apiFormsUpdateWidget(token, updateBody);

        if (status === 201) {
          message.success('Form widget updated successfully!');
          loadFormData(token, responseGetForm.data.id);
        } else {
          message.error('Failed to update form widget!');
        }
      }
      setSaving(false);
    },
    [token, responseGetForm],
  );

  if (!formId) {
    message.error('Missing formId in url!');
    navigate('/');
    return null;
  }

  return (
    <Card bordered={false} title="Form Widget Edit">
      <Typography.Title level={2}>Form Widget Edit</Typography.Title>
      {responseGetForm?.data?.widget ? (
        <Form
          form={formStyleForm}
          onFinish={formHandleUpdate}
          disabled={saving}
        >
          <ImageInput
            name="imageId"
            label="Widget image"
            userId={getOwnerOfTheForm(responseGetForm.data)?.id}
            defaultImageId={responseGetForm.data.widget?.image?.aliasId}
            useAliasId
            required={false}
          />
          <Form.Item
            label="Widget Title"
            name="widgetTitle"
            initialValue={responseGetForm.data.widget?.widgetTitle}
            rules={[
              {
                max: LengthConstants.FORM_WIDGET_TITLE,
                message: `Max length is ${LengthConstants.FORM_WIDGET_TITLE}`,
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Widget Button Text"
            name="widgetButtonText"
            initialValue={responseGetForm.data.widget?.widgetButtonText}
            rules={[
              {
                max: LengthConstants.FORM_WIDGET_BUTTON_TEXT,
                message: `Max length is ${LengthConstants.FORM_WIDGET_BUTTON_TEXT}`,
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Widget Button Background Color"
            name="widgetButtonBackgroundColor"
            initialValue={
              responseGetForm.data.widget?.widgetButtonBackgroundColor
            }
            getValueFromEvent={(color) => {
              return !color || color?.cleared ? null : color.toHex();
            }}
          >
            <ColorPicker allowClear />
          </Form.Item>
          <Form.Item
            label="Widget Button Text Color"
            name="widgetButtonTextColor"
            initialValue={responseGetForm.data.widget?.widgetButtonTextColor}
            getValueFromEvent={(color) => {
              return !color || color?.cleared ? null : color.toHex();
            }}
          >
            <ColorPicker allowClear />
          </Form.Item>
          <Form.Item
            label="Widget Label"
            name="widgetLabel"
            initialValue={responseGetForm.data.widget?.widgetLabel}
            rules={[
              {
                max: LengthConstants.FORM_WIDGET_LABEL,
                message: `Max length is ${LengthConstants.FORM_WIDGET_LABEL}`,
                required: true,
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Default Visible"
            name="defaultVisible"
            initialValue={responseGetForm.data.widget?.defaultVisible}
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            label="Offset Bottom"
            name="offsetBottom"
            initialValue={responseGetForm.data.widget?.offsetBottom}
            rules={[
              {
                type: 'number',
                required: true,
              },
            ]}
          >
            <InputNumber suffix="px" />
          </Form.Item>
          <Form.Item
            label="Hide On Bottom"
            name="hideOnBottom"
            initialValue={responseGetForm.data.widget?.hideOnBottom}
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            label="Hide Show On Scroll"
            name="hideShowOnScroll"
            initialValue={responseGetForm.data.widget?.hideShowOnScroll}
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          <Form.Item
            label="Widget Theme"
            name="widgetTheme"
            initialValue={responseGetForm.data.widget?.widgetTheme}
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Select placeholder="Select a theme">
              {Object.values(FormWidgetTheme).map((theme) => (
                <Select.Option key={theme} value={theme}>
                  {theme}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Save Changes
            </Button>
          </Form.Item>
        </Form>
      ) : null}
    </Card>
  );
}

export default FormWidgetEdit;
