import { API_URL } from './constants';
import {
  ApiPlatformSettingsGetResponse,
  ApiPlatformSettingsUpdateRequest,
  ApiPlatformSettingsUpdateResponse,
} from './types/platform-settings';

export async function apiGetPlatformSettings(
  token: string,
): Promise<[ApiPlatformSettingsGetResponse, number]> {
  const url = new URL(
    `${API_URL}/admin/platform-settings/get-platform-settings`,
  ).href;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return [
    (await response.json()) as ApiPlatformSettingsGetResponse,
    response.status,
  ];
}

export async function apiUpdatePlatformSettings(
  token: string,
  body: ApiPlatformSettingsUpdateRequest,
): Promise<[ApiPlatformSettingsUpdateResponse, number]> {
  const url = new URL(
    `${API_URL}/admin/platform-settings/update-platform-settings`,
  ).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [
    (await response.json()) as ApiPlatformSettingsUpdateResponse,
    response.status,
  ];
}
