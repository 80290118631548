import { Layout } from 'antd';
import { Content } from 'antd/es/layout/layout';
import React from 'react';
import { useSelector } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import SideNavigation from '../components/SideNavigation';
import CreateWizardPage from '../pages/CreateWizard';
import FormEdit from '../pages/FormEdit';
import LoginPage from '../pages/Login/login';
import OptionCreatePage from '../pages/OptionCreate';
import OptionEdit from '../pages/OptionEdit';
import StepCreatePage from '../pages/StepCreate';
import StepEdit from '../pages/StepEdit';
import UserEdit from '../pages/UserEdit';
import UsersPage from '../pages/Users';
import { selectUserState } from '../store/selectors/user';
import { RouterPaths } from './paths';
import StatisticsPage from '../pages/Statistics';
import TranslationsEdit from '../pages/TranslationsEdit';
import FormOriginsEditPage from '../pages/FormOriginsEdit';
import FormNotificationEmailsEditPage from '../pages/FormNotificationEmailsEdit';
import CreatePopupPage from '../pages/CreatePopup';
import RegistrationsPage from '../pages/Registrations';
import AgencyUsersPage from '../pages/AgencyUsers';
import AgencyUserCreatePage from '../pages/AgencyUserCreate';
import AgencyUserEditPage from '../pages/AgencyUserEdit';
import FeedbacksPage from '../pages/Feedbacks';
import SingleFeedbackPage from '../pages/SingleFeedback';
import SendAnnoouncementPage from '../pages/SendAnnouncement';
import FormWebhooksEditPage from '../pages/FormWebhooksEdit';
import FormCNAMEsEditPage from '../pages/FormCNAMEsEdit';
import FormSEOEdit from '../pages/FormSEOEdit';
import UserUsagePage from '../pages/UserUsagePage';
import UserBillingPage from '../pages/UserBillingPage';
import FeatureLimitsEditPage from '../pages/FeatureLimitsEdit';
import UserFeatureLimitsPage from '../pages/UserFeatureLimits';
import BillingSettingsPage from '../pages/BillingSettings';
import FormStyleEdit from '../pages/FormStyleEdit';
import FormWidgetEdit from '../pages/FormWidgetEdit';
import PlatformSettingsEdit from '../pages/PlatformSettingsEdit';

function AppRouter() {
  const { isLoggedIn } = useSelector(selectUserState);

  return (
    <BrowserRouter>
      {!isLoggedIn ? (
        <Routes>
          <Route path={RouterPaths.LOGIN} element={<LoginPage />} />
          <Route
            path={RouterPaths.ALL}
            element={<Navigate to={RouterPaths.LOGIN} />}
          />
        </Routes>
      ) : (
        <Layout style={{ minHeight: '100vh' }}>
          <SideNavigation />
          <Layout
            className="site-layout"
            style={{ marginTop: '25px', marginBottom: '100px' }}
          >
            <Content style={{ margin: '0 16px' }}>
              <Routes>
                <Route path={RouterPaths.USERS} element={<UsersPage />} />
                <Route
                  path={RouterPaths.FEATURE_LIMITS}
                  element={<FeatureLimitsEditPage />}
                />
                <Route
                  path={RouterPaths.STATISTICS}
                  element={<StatisticsPage />}
                />
                <Route
                  path={RouterPaths.CREATE_WIZARD}
                  element={<CreateWizardPage />}
                />
                <Route
                  path={RouterPaths.CREATE_WIZARD}
                  element={<CreateWizardPage />}
                />
                <Route
                  path={`${RouterPaths.USERS}/:userId`}
                  element={<UserEdit />}
                />
                <Route
                  path={`${RouterPaths.USERS}/:userId/usage`}
                  element={<UserUsagePage />}
                />
                <Route
                  path={`${RouterPaths.USERS}/:userId/billing`}
                  element={<UserBillingPage />}
                />
                <Route
                  path={`${RouterPaths.USERS}/:userId/feature-limits`}
                  element={<UserFeatureLimitsPage />}
                />
                <Route
                  path={`${RouterPaths.FORMS}/:formId`}
                  element={<FormEdit />}
                />
                <Route
                  path={`${RouterPaths.FORMS}/:formId/origins`}
                  element={<FormOriginsEditPage />}
                />
                <Route
                  path={`${RouterPaths.FORMS}/:formId/emails`}
                  element={<FormNotificationEmailsEditPage />}
                />
                <Route
                  path={`${RouterPaths.FORMS}/:formId/create-popup`}
                  element={<CreatePopupPage />}
                />
                <Route
                  path={`${RouterPaths.FORMS}/:formId/edit-webhooks`}
                  element={<FormWebhooksEditPage />}
                />
                <Route
                  path={`${RouterPaths.FORMS}/:formId/style`}
                  element={<FormStyleEdit />}
                />
                <Route
                  path={`${RouterPaths.FORMS}/:formId/widget`}
                  element={<FormWidgetEdit />}
                />
                <Route
                  path={`${RouterPaths.STEPS}/:stepId`}
                  element={<StepEdit />}
                />
                <Route
                  path={`${RouterPaths.OPTIONS}/:optionId`}
                  element={<OptionEdit />}
                />
                <Route
                  path={`${RouterPaths.STEPS}/create/:formId`}
                  element={<StepCreatePage />}
                />
                <Route
                  path={`${RouterPaths.OPTIONS}/create/:stepId`}
                  element={<OptionCreatePage />}
                />
                <Route
                  path={`${RouterPaths.TRANSLATIONS}/:formId`}
                  element={<TranslationsEdit />}
                />
                <Route
                  path={RouterPaths.REGISTRATIONS}
                  element={<RegistrationsPage />}
                />
                <Route
                  path={RouterPaths.AGENCY_USERS}
                  element={<AgencyUsersPage />}
                />
                <Route
                  path={`${RouterPaths.AGENCY_USERS}/:agencyUserId`}
                  element={<AgencyUserEditPage />}
                />
                <Route
                  path={`${RouterPaths.AGENCY_USERS}/create`}
                  element={<AgencyUserCreatePage />}
                />
                <Route
                  path={RouterPaths.FEEDBACKS}
                  element={<FeedbacksPage />}
                />
                <Route
                  path={`${RouterPaths.FEEDBACKS}/:feedbackId`}
                  element={<SingleFeedbackPage />}
                />
                <Route
                  path={RouterPaths.SEND_ANNOUNCEMENT}
                  element={<SendAnnoouncementPage />}
                />
                <Route
                  path={`${RouterPaths.FORMS}/:formId/cnames`}
                  element={<FormCNAMEsEditPage />}
                />
                <Route
                  path={`${RouterPaths.FORMS}/:formId/seo`}
                  element={<FormSEOEdit />}
                />
                <Route
                  path={`${RouterPaths.BILLING_SETTINGS}`}
                  element={<BillingSettingsPage />}
                />
                <Route
                  path={`${RouterPaths.PLATFORM_SETTINGS_EDIT}`}
                  element={<PlatformSettingsEdit />}
                />
                <Route
                  path={RouterPaths.ALL}
                  element={<Navigate to={RouterPaths.USERS} />}
                />
              </Routes>
            </Content>
          </Layout>
        </Layout>
      )}
    </BrowserRouter>
  );
}

export default AppRouter;
