import {
  Button,
  Card,
  Checkbox,
  ColorPicker,
  Form,
  Input,
  InputNumber,
  Select,
  Typography,
  message,
} from 'antd';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';
import {
  apiFormsCreatePopup,
  apiFormsGet,
  getOwnerOfTheForm,
} from '../../services/api/forms';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/selectors/user';
import {
  ApiFormsCreatePopupRequestBody,
  ApiFormsGetResponse,
  PopupType,
} from '../../services/api/types/forms';
import { RouterPaths } from '../../router/paths';
import { useWatch } from 'antd/es/form/Form';
import ImageInput from '../../components/ImageInput/imageInput';

interface CreatePopupFormState {
  title: string;
  label: string;
  proceedText: string;
  cancelText: string;
  triggerTime: string;
  intentTrigger: boolean;
  type: PopupType;
  imageId?: string | null;
  backgroundColor?: string | null;
  textColor?: string | null;
  buttonBackgroundColor?: string | null;
  buttonTextColor?: string | null;
  buttonBorderRadius?: number | null;
}

function CreatePopupPage() {
  const { token } = useSelector(selectUserState);
  const { formId } = useParams();
  const navigate = useNavigate();
  const [createPopupForm] = Form.useForm<CreatePopupFormState>();
  const [form, setForm] = React.useState<ApiFormsGetResponse | null>(null);

  async function loadFormData(tk: string, fid: number) {
    const [response, status] = await apiFormsGet(tk, fid);
    if (status === 200) {
      setForm(response);
    } else {
      message.error({ content: 'Error loading form!' });
      navigate('/');
    }
  }

  const popupType = useWatch('type', createPopupForm);

  const createPopupFormSubmit = React.useCallback(
    async (values: CreatePopupFormState) => {
      if (formId && token && values && Object.keys(values).length) {
        const updateFormRequestBody: ApiFormsCreatePopupRequestBody = {
          formId: parseInt(formId, 10),
          ...values,
          triggerTime: parseInt(values.triggerTime, 10),
          imageId: values.imageId || null,
        };
        const [response, status] = await apiFormsCreatePopup(
          updateFormRequestBody,
          token,
        );
        if (status === 200 && response.data.success) {
          message.success({ content: 'Form updated successfully!' });
          navigate(`${RouterPaths.FORMS}/${formId}`);
        } else {
          message.error({ content: 'Failed form update!' });
        }
      }
    },
    [token, formId],
  );

  useEffect(() => {
    if (token && formId) {
      loadFormData(token, parseInt(formId, 10));
    }
  }, [formId]);

  if (!formId) {
    message.error('Missing formId in url!');
    navigate('/');
    return null;
  }

  return (
    <Card bordered={false} title="Single Form Edit">
      <Typography.Title level={2}>Create Popup</Typography.Title>
      {form?.data?.aliasId ? (
        <Form form={createPopupForm} onFinish={createPopupFormSubmit}>
          <Form.Item
            label="Type"
            name="type"
            required={true}
            initialValue={PopupType.SIMPLE}
            rules={[{ required: true, message: 'Type is required' }]}
          >
            <Select>
              <Select.Option value={PopupType.SIMPLE}>Simple</Select.Option>
              <Select.Option value={PopupType.TAKEOVER}>Takeover</Select.Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Title Text"
            name="title"
            required={true}
            initialValue={'Wait, You Still Didn’t Get Your Free Estimate?'}
            rules={[
              {
                max: 512,
                required: true,
                message: 'Title must be 512 chars long!',
              },
            ]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            label="Label Text"
            name="label"
            required={true}
            initialValue={'Takes under minute to complete'}
            rules={[
              {
                max: 512,
                required: true,
                message: 'Label must be 512 chars long!',
              },
            ]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            label="Button Proceed Text"
            name="proceedText"
            required={true}
            initialValue={'Start Estimation'}
            rules={[
              {
                max: 128,
                required: true,
                message: 'Proceed Text must be 128 chars long!',
              },
            ]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            label="Button Cancel Text"
            name="cancelText"
            required={true}
            initialValue={'Not Now'}
            rules={[
              {
                max: 128,
                required: true,
                message: 'Cancel Text must be 128 chars long!',
              },
            ]}
          >
            <Input type="text" />
          </Form.Item>
          <Form.Item
            label="Trigger time (ms after page load)"
            name="triggerTime"
            required={true}
            initialValue={30000}
            rules={[{ required: true, message: 'Trigger time is required' }]}
          >
            <Input type="number" />
          </Form.Item>
          <Form.Item
            label="Intent Trigger"
            name="intentTrigger"
            initialValue={false}
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
          {popupType === PopupType.TAKEOVER ? (
            <ImageInput
              form={createPopupForm}
              name="imageId"
              label="Image"
              useAliasId
              allowClear
              userId={getOwnerOfTheForm(form.data)?.id}
            />
          ) : null}
          {popupType === PopupType.TAKEOVER ? (
            <Form.Item
              label={'Background color'}
              name={'backgroundColor'}
              getValueFromEvent={(color) => {
                return color.toHex();
              }}
            >
              <ColorPicker />
            </Form.Item>
          ) : null}
          {popupType === PopupType.TAKEOVER ? (
            <Form.Item
              label={'Text color'}
              name={'textColor'}
              getValueFromEvent={(color) => {
                return color.toHex();
              }}
            >
              <ColorPicker />
            </Form.Item>
          ) : null}
          {popupType === PopupType.TAKEOVER ? (
            <Form.Item
              label={'Button Background color'}
              name={'buttonBackgroundColor'}
              getValueFromEvent={(color) => {
                return color.toHex();
              }}
            >
              <ColorPicker />
            </Form.Item>
          ) : null}
          {popupType === PopupType.TAKEOVER ? (
            <Form.Item
              label={'Button Text color'}
              name={'buttonTextColor'}
              getValueFromEvent={(color) => {
                return color.toHex();
              }}
            >
              <ColorPicker />
            </Form.Item>
          ) : null}
          {popupType === PopupType.TAKEOVER ? (
            <Form.Item
              label={'Button border radius'}
              name={'buttonBorderRadius'}
              labelCol={{ span: 24 }}
            >
              <InputNumber suffix="px" />
            </Form.Item>
          ) : null}
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ marginTop: 55 }}>
              Create and save
            </Button>
          </Form.Item>
        </Form>
      ) : null}
    </Card>
  );
}

export default CreatePopupPage;
