import { API_URL } from './constants';
import {
  AdminUpdateBillingSubscriptionRequest,
  AdminUpdateBillingSubscriptionResponse,
  CancelSubscriptionRequest,
  CancelSubscriptionResponse,
  ChangeSubscriptionRequest,
  ChangeSubscriptionResponse,
  CreateCheckoutRequest,
  CreateCheckoutResponse,
  DownloadInvoiceRequest,
  DownloadInvoiceResponse,
  GetActiveSubscriptionRequest,
  GetActiveSubscriptionResponse,
  GetBillingSettingsRequest,
  GetBillingSettingsResponse,
  ListAvailableSubscriptionsRequest,
  ListAvailableSubscriptionsResponse,
  ListInvoicesRequest,
  ListInvoicesResponse,
  ListUserBillingAccountsRequest,
  ListUserBillingAccountsResponse,
  SyncUserSubscriptionWithBillingAccountRequest,
  SyncUserSubscriptionWithBillingAccountResponse,
  UpdateBillingSettingsRequest,
  UpdateBillingSettingsResponse,
  UpdateUserBillingAccountRequest,
  UpdateUserBillingAccountResponse,
} from './types/billing';

export async function apiChangeSubscription(
  token: string,
  body: ChangeSubscriptionRequest,
): Promise<[ChangeSubscriptionResponse, number]> {
  const url = new URL(`${API_URL}/admin/billing/change-subscription`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [
    (await response.json()) as ChangeSubscriptionResponse,
    response.status,
  ];
}

export async function apiCreateCheckout(
  token: string,
  body: CreateCheckoutRequest,
): Promise<[CreateCheckoutResponse, number]> {
  const url = new URL(`${API_URL}/admin/billing/create-checkout`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [(await response.json()) as CreateCheckoutResponse, response.status];
}

export async function apiCancelSubscription(
  token: string,
  body: CancelSubscriptionRequest,
): Promise<[CancelSubscriptionResponse, number]> {
  const url = new URL(`${API_URL}/admin/billing/cancel-subscription`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [
    (await response.json()) as CancelSubscriptionResponse,
    response.status,
  ];
}

export async function apiGetActiveSubscription(
  token: string,
  body: GetActiveSubscriptionRequest,
): Promise<[GetActiveSubscriptionResponse, number]> {
  const url = new URL(`${API_URL}/admin/billing/get-active-subscription`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [
    (await response.json()) as GetActiveSubscriptionResponse,
    response.status,
  ];
}

export async function apiListAvailableSubscriptions(
  token: string,
  body: ListAvailableSubscriptionsRequest,
): Promise<[ListAvailableSubscriptionsResponse, number]> {
  const url = new URL(`${API_URL}/admin/billing/list-available-subscriptions`)
    .href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [
    (await response.json()) as ListAvailableSubscriptionsResponse,
    response.status,
  ];
}

export async function apiListInvoices(
  token: string,
  body: ListInvoicesRequest,
): Promise<[ListInvoicesResponse, number]> {
  const url = new URL(`${API_URL}/admin/billing/list-invoices`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [(await response.json()) as ListInvoicesResponse, response.status];
}

export async function apiDownloadInvoice(
  token: string,
  body: DownloadInvoiceRequest,
): Promise<[DownloadInvoiceResponse, number]> {
  const url = new URL(`${API_URL}/admin/billing/download-invoice`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [(await response.json()) as DownloadInvoiceResponse, response.status];
}

export async function apiListUserBillingAccounts(
  token: string,
  body: ListUserBillingAccountsRequest,
): Promise<[ListUserBillingAccountsResponse, number]> {
  const url = new URL(`${API_URL}/admin/billing/list-user-billing-accounts`)
    .href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [
    (await response.json()) as ListUserBillingAccountsResponse,
    response.status,
  ];
}

export async function apiGetBillingSettings(
  token: string,
  body: GetBillingSettingsRequest,
): Promise<[GetBillingSettingsResponse, number]> {
  const url = new URL(`${API_URL}/admin/billing/get-billing-settings`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [
    (await response.json()) as GetBillingSettingsResponse,
    response.status,
  ];
}

export async function apiUpdateBillingSettings(
  token: string,
  body: UpdateBillingSettingsRequest,
): Promise<[UpdateBillingSettingsResponse, number]> {
  const url = new URL(`${API_URL}/admin/billing/update-billing-settings`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [
    (await response.json()) as UpdateBillingSettingsResponse,
    response.status,
  ];
}

export async function apiUpdateBillingSubscription(
  token: string,
  body: AdminUpdateBillingSubscriptionRequest,
): Promise<[AdminUpdateBillingSubscriptionResponse, number]> {
  const url = new URL(`${API_URL}/admin/billing/update-billing-subscription`)
    .href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [
    (await response.json()) as AdminUpdateBillingSubscriptionResponse,
    response.status,
  ];
}

export async function apiSyncUserSubscriptionWithBillingAccount(
  token: string,
  body: SyncUserSubscriptionWithBillingAccountRequest,
): Promise<[SyncUserSubscriptionWithBillingAccountResponse, number]> {
  const url = new URL(
    `${API_URL}/admin/billing/sync-user-subscription-with-billing-account`,
  ).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [
    (await response.json()) as SyncUserSubscriptionWithBillingAccountResponse,
    response.status,
  ];
}

export async function apiUpdateUserBillingAccount(
  token: string,
  body: UpdateUserBillingAccountRequest,
): Promise<[UpdateUserBillingAccountResponse, number]> {
  const url = new URL(`${API_URL}/admin/billing/update-user-billing-account`)
    .href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [
    (await response.json()) as UpdateUserBillingAccountResponse,
    response.status,
  ];
}
