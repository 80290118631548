import { Button, Card, Input, Tag, Typography, message } from 'antd';
import React from 'react';
import { useNavigate, useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/selectors/user';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { CNAMEDto } from '../../services/api/types/cname';
import {
  apiCNAMEAdd,
  apiCNAMEDelete,
  apiCNAMEList,
  apiCNAMEVerify,
} from '../../services/api/cname';

export interface CNAMEItem extends Partial<CNAMEDto> {}

function DNSRecord({
  type,
  name,
  value,
}: {
  type: string | null;
  name: string | null;
  value: string | null;
}) {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        marginBottom: 10,
        width: '100%',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: 'fit-content',
          marginRight: '8px',
        }}
      >
        <span>TYPE</span>
        <Input value={type ?? 'N/A'} />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
          marginRight: '8px',
        }}
      >
        <span>Hostname</span>
        <Input value={name ?? 'N/A'} />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: '100%',
        }}
      >
        <span>Value</span>
        <Input value={value ?? 'N/A'} />
      </div>
    </div>
  );
}

function FormCNAMEsEditPage() {
  const { token } = useSelector(selectUserState);
  const { formId } = useParams();
  const navigate = useNavigate();
  const [cnames, setCnames] = React.useState<CNAMEItem[]>([]);
  const [loading, setLoading] = React.useState(false);

  async function loadFormData(tk: string, fid: number) {
    setLoading(true);
    const [response, status] = await apiCNAMEList(tk, {
      formId: fid,
      pagination: { limit: 9999 },
    });
    if (status === 201) {
      setCnames(response.cnames);
    } else {
      message.error('Failed to load form CNAMEs data!');
    }
    setLoading(false);
  }

  async function deleteCNAME(tk: string, cnameId: string, formId: number) {
    setLoading(true);
    const [, status] = await apiCNAMEDelete(tk, {
      cnameId,
    });
    if (status === 201) {
      message.success('CNAME deleted successfully!');
      loadFormData(tk, formId);
    } else {
      message.error('Failed to delete CNAMEs data!');
      setLoading(false);
    }
  }

  async function createCNAME(tk: string, domain: string, formId: number) {
    setLoading(true);
    const [, status] = await apiCNAMEAdd(tk, {
      formId,
      domain,
    });
    if (status === 201) {
      message.success('CNAME created successfully!');
      loadFormData(tk, formId);
    } else {
      message.error('Failed to create CNAMEs data!');
      setLoading(false);
    }
  }

  async function verifyCNAME(tk: string, cnameId: string, formId: number) {
    setLoading(true);
    const [response, status] = await apiCNAMEVerify(tk, {
      cnameId,
    });
    if (status === 201) {
      if (response.verified) {
        message.success('CNAME verify successfully!');
      } else {
        message.warning(
          'Could not verify CNAME! Please check your DNS settings.',
        );
      }
      loadFormData(tk, formId);
    } else {
      message.error('CNAME verify failed!');
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (token && formId) {
      loadFormData(token, parseInt(formId, 10));
    }
  }, [token, formId]);

  if (!formId) {
    message.error('Missing formId in url!');
    navigate('/');
    return null;
  }

  if (!token) {
    return null;
  }

  return (
    <Card bordered={false} title="Form CNAMEs">
      <Typography.Title level={2}>Form Origins Edit</Typography.Title>
      <div
        style={{ marginBottom: 20, display: 'flex', flexDirection: 'column' }}
      >
        {cnames.map((cname, index) => (
          <div
            key={index}
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginBottom: 24,
              width: '100%',
              maxWidth: '850px',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                marginBottom: 4,
              }}
            >
              <Tag
                color={cname.verified ? 'green' : cname.id ? 'orange' : 'red'}
                style={{
                  width: '100px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {cname.verified
                  ? 'Verified'
                  : cname.id
                  ? 'Not Verified'
                  : 'Not Saved'}
              </Tag>
              <Input
                style={{ width: '100%', marginRight: 10 }}
                value={cname.domain}
                onChange={(e) => {
                  const newCnames = [...cnames];
                  newCnames[index].domain = e.target.value;
                  setCnames(newCnames);
                }}
                disabled={loading}
              />
              <Button
                disabled={loading}
                type="primary"
                danger
                icon={<MinusCircleOutlined />}
                onClick={() => {
                  if (!cname.id) {
                    const newCnames = [...cnames.filter((_, i) => i !== index)];
                    setCnames(newCnames);
                  } else {
                    deleteCNAME(token, cname.id, parseInt(formId, 10));
                  }
                }}
              >
                Delete
              </Button>
              {cname.verified ? null : (
                <Button
                  disabled={loading}
                  type="primary"
                  icon={<PlusOutlined />}
                  style={{ marginLeft: 10 }}
                  onClick={() => {
                    if (cname.id) {
                      verifyCNAME(token, cname.id, parseInt(formId, 10));
                    } else {
                      if (cname.domain?.length) {
                        createCNAME(token, cname.domain, parseInt(formId, 10));
                      } else {
                        message.error('Domain is required!');
                      }
                    }
                  }}
                >
                  {cname.id ? 'Verify' : 'Save'}
                </Button>
              )}
            </div>
            {cname.id ? (
              <>
                <DNSRecord
                  type={'CNAME'}
                  name={cname.domain ?? null}
                  value={'client.tapform.io'}
                />
                <DNSRecord
                  type={cname.verificationType ?? null}
                  name={cname.verificationName ?? null}
                  value={cname.verificationValue ?? null}
                />
                <DNSRecord
                  type={cname.sslVerificationType ?? null}
                  name={cname.sslVerificationName ?? null}
                  value={cname.sslVerificationValue ?? null}
                />
              </>
            ) : null}
          </div>
        ))}
      </div>
      <Button
        type="primary"
        icon={<PlusOutlined />}
        onClick={() => {
          setCnames([...cnames, {}]);
        }}
        disabled={loading}
      >
        Add CNAME
      </Button>
    </Card>
  );
}

export default FormCNAMEsEditPage;
