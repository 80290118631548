export enum RouterPaths {
  USERS = '/users',
  LOGIN = '/login',
  CREATE_WIZARD = '/create-wizard',
  FORMS = '/forms',
  STEPS = '/steps',
  OPTIONS = '/options',
  STATISTICS = '/statistics',
  POPUPS = '/popups',
  FEEDBACKS = '/feedbacks',
  TRANSLATIONS = '/translations',
  REGISTRATIONS = '/registrations',
  AGENCY_USERS = '/agency-users',
  SEND_ANNOUNCEMENT = '/send-announcement',
  FEATURE_LIMITS = '/feature-limits',
  BILLING_SETTINGS = '/billing-settings',
  PLATFORM_SETTINGS_EDIT = '/platform-settings-edit',
  ALL = '*',
}
