/* eslint-disable @typescript-eslint/restrict-template-expressions */
import {
  Button,
  Card,
  Form,
  Input,
  message,
  Modal,
  Select,
  Switch,
  Typography,
} from 'antd';
import Table from 'antd/es/table';
import type { ColumnsType } from 'antd/es/table';
import React from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router';
import Timezone from 'timezone-enum';
import Image from '../../components/Image';
import { RouterPaths } from '../../router/paths';
import { apiFormsList } from '../../services/api/forms';
import {
  ApiFormsListResponse,
  FormEntity,
} from '../../services/api/types/forms';
import {
  ApiUsersGetResponse,
  ApiUsersUpdateRequestBody,
} from '../../services/api/types/users';
import {
  apiUsersDelete,
  apiUsersGet,
  apiUsersUpdate,
} from '../../services/api/users';
import { selectUserState } from '../../store/selectors/user';

export interface UserEditFormState {
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  phoneNumber?: string;
  activeEmailNotifications?: boolean;
  notificationEmail?: string;
  activeWeeklyReport?: boolean;
  timezone?: Timezone;
  onboardingStep?: number;
}

interface DataType extends FormEntity {
  key: string;
}

function UserEdit() {
  const navigate = useNavigate();
  const { userId } = useParams();
  const { token } = useSelector(selectUserState);
  const [deleteOpen, setDeleteOpen] = React.useState<boolean>(false);
  const [deleteLoading, setDeleteLoading] = React.useState<boolean>(false);

  const [userEditForm] = Form.useForm<UserEditFormState>();
  const [loadedUser, setLoadedUser] =
    React.useState<ApiUsersGetResponse | null>(null);
  const [loadedForms, setLoadedForms] =
    React.useState<ApiFormsListResponse | null>(null);

  async function loadForms(tk: string, uid: number) {
    const [response, status] = await apiFormsList(tk, uid);
    if (status !== 200) {
      message.error({ content: 'Could not load user forms' });
    } else {
      setLoadedForms(response);
    }
  }

  async function loadUser(tk: string, uid: number) {
    const [response, status] = await apiUsersGet(uid, tk);
    if (status === 200 && response) {
      setLoadedUser(response);
      loadForms(tk, response.id);
    } else {
      message.error({ content: 'User not found!' });
      navigate(RouterPaths.USERS);
    }
  }

  React.useEffect(() => {
    if (userId && token) {
      loadUser(token, parseInt(userId, 10));
    }
  }, [userId, token]);

  const userEditSubmitCallback = React.useCallback(
    async (values: UserEditFormState) => {
      console.log(values);
      if (loadedUser && token) {
        const updateBodyRequest: ApiUsersUpdateRequestBody = {
          id: loadedUser.id,
        };
        let shouldUpdate = false;
        const onboardingStepParsed =
          values.onboardingStep === 5 ? null : values.onboardingStep;
        if (values.firstName !== loadedUser.firstName) {
          updateBodyRequest.firstName = values.firstName;
          shouldUpdate = true;
        }
        if (values.lastName !== loadedUser.lastName) {
          updateBodyRequest.lastName = values.lastName;
          shouldUpdate = true;
        }
        if (values.email !== loadedUser.email) {
          updateBodyRequest.email = values.email;
          shouldUpdate = true;
        }
        if (values.password) {
          updateBodyRequest.password = values.password;
          shouldUpdate = true;
        }
        if (values.phoneNumber !== loadedUser.phoneNumber) {
          updateBodyRequest.phoneNumber = values.phoneNumber;
          shouldUpdate = true;
        }
        if (values.notificationEmail !== loadedUser.notificationEmail) {
          updateBodyRequest.notificationEmail = values.notificationEmail;
          shouldUpdate = true;
        }
        if (
          values.activeEmailNotifications !==
          loadedUser.activeEmailNotifications
        ) {
          updateBodyRequest.activeEmailNotifications =
            values.activeEmailNotifications;
          shouldUpdate = true;
        }
        if (values.activeWeeklyReport !== loadedUser.activeWeeklyReport) {
          updateBodyRequest.activeWeeklyReport = values.activeWeeklyReport;
          shouldUpdate = true;
        }
        if (values.timezone !== loadedUser.timezone) {
          updateBodyRequest.timezone = values.timezone;
          shouldUpdate = true;
        }
        if (onboardingStepParsed !== loadedUser.onboardingStep) {
          updateBodyRequest.onboardingStep = onboardingStepParsed;
          shouldUpdate = true;
        }

        if (shouldUpdate) {
          const [response, status] = await apiUsersUpdate(
            updateBodyRequest,
            token,
          );
          if (status === 200) {
            message.success({ content: 'User updated successfully!' });
            loadUser(token, response.id);
          } else {
            message.error({ content: 'Failed user update!' });
          }
        }
      }
    },
    [loadedUser, token],
  );

  const columns = React.useMemo<ColumnsType<DataType>>(
    () => [
      {
        key: 'id',
        title: 'ID',
        dataIndex: 'id',
        fixed: true,
        render: (v) => `${v}`,
      },
      {
        key: 'logoImage',
        title: 'Logo',
        dataIndex: 'style',
        fixed: true,
        render: (v, record) =>
          record.style?.logoImage?.id ? (
            <Image id={record.style?.logoImage?.id} />
          ) : (
            'No Logo'
          ),
      },
      {
        key: 'origin',
        title: 'Origin',
        dataIndex: 'origin',
        fixed: true,
        render: (v) => `${v}`,
      },
      {
        key: 'name',
        title: 'Form Name',
        dataIndex: 'name',
        render: (v) => `${v}`,
      },
      {
        key: 'createdAt',
        title: 'Created at',
        dataIndex: 'createdAt',
        render: (v) => `${v}`,
      },
      {
        key: 'deletedAt',
        title: 'Deleted at',
        dataIndex: 'deletedAt',
        render: (v) => `${v}`,
      },
      {
        key: 'brandColor',
        title: 'Brand Color',
        dataIndex: 'style',
        render: (v, record) => (
          <div
            style={{
              height: '32px',
              width: '32px',
              backgroundColor: `#${record.style?.buttonBackgroundColor}`,
              borderRadius: '50%',
            }}
          />
        ),
      },
      {
        key: 'slug',
        title: 'Slug',
        dataIndex: 'slug',
        render: (v) => `${v}`,
      },
      {
        key: 'developmentMode',
        title: 'Is In Development Mode',
        dataIndex: 'settings',
        render: (v) => `${v.developmentMode}`,
      },
    ],
    [],
  );

  const renderStepText = (step: number | null) => {
    switch (step) {
      case 1: {
        return 'First step (user information)';
      }
      case 2: {
        return 'Second step (company information)';
      }
      case 3: {
        return 'Third step (template select)';
      }
      case 4: {
        return 'Fourth step (color and logo)';
      }
      default: {
        return 'Finished onboarding';
      }
    }
  };

  if (!userId) {
    navigate(RouterPaths.USERS);
    return null;
  }

  return (
    <Card bordered={false} title="Single User">
      <Typography.Title level={2}>User Edit</Typography.Title>
      <Button
        type="primary"
        onClick={() => {
          navigate(`${RouterPaths.USERS}/${userId}/billing`);
        }}
        style={{
          marginBottom: 20,
          marginRight: 8,
        }}
      >
        Billing/Payment Settings
      </Button>
      <Button
        type="primary"
        onClick={() => {
          navigate(`${RouterPaths.USERS}/${userId}/usage`);
        }}
        style={{
          marginBottom: 20,
          marginRight: 8,
        }}
      >
        View/Edit Usage
      </Button>
      <Button
        type="primary"
        onClick={() => {
          navigate(`${RouterPaths.USERS}/${userId}/feature-limits`);
        }}
        style={{
          marginBottom: 20,
          marginRight: 8,
        }}
      >
        Edit Feature Limits
      </Button>
      <Button
        type="primary"
        danger
        onClick={() => {
          setDeleteOpen(true);
        }}
        style={{
          marginBottom: 20,
        }}
      >
        Delete User
      </Button>
      {loadedUser ? (
        <Modal
          title="Are you sure?"
          onOk={async () => {
            if (token) {
              setDeleteLoading(true);
              try {
                const [, status] = await apiUsersDelete(
                  { userId: loadedUser.id },
                  token,
                );
                if (status === 201) {
                  message.success('Successfully deleted!');
                  navigate(RouterPaths.USERS);
                }
              } catch (e) {
                message.error('Something went wrong!');
                setDeleteLoading(false);
              }
            }
          }}
          onCancel={() => {
            setDeleteOpen(false);
          }}
          confirmLoading={deleteLoading}
          open={deleteOpen}
        />
      ) : null}
      {loadedUser ? (
        <Form form={userEditForm} onFinish={userEditSubmitCallback}>
          <Form.Item
            label="First Name"
            name="firstName"
            rules={[
              {
                required: false,
                message: 'Please input first name!',
                max: 100,
              },
            ]}
            initialValue={loadedUser.firstName}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Last Name"
            name="lastName"
            rules={[
              { required: false, message: 'Please input last name!', max: 100 },
            ]}
            initialValue={loadedUser.lastName}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Email"
            name="email"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              { required: false, message: 'Please input email!', max: 255 },
            ]}
            initialValue={loadedUser.email}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Notifcation Email"
            name="notificationEmail"
            rules={[
              {
                type: 'email',
                message: 'The input is not valid E-mail!',
              },
              { required: false, message: 'Please input email!', max: 255 },
            ]}
            initialValue={loadedUser.notificationEmail}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Phone number"
            name="phoneNumber"
            rules={[
              {
                required: false,
                message: 'Please input phone number!',
                max: 255,
              },
            ]}
            initialValue={loadedUser.phoneNumber}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="New Password"
            name="password"
            rules={[
              { required: false, message: 'Please input password!', max: 255 },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Active Email Notifications"
            name="activeEmailNotifications"
            valuePropName="checked"
            initialValue={loadedUser.activeEmailNotifications}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label="Active Weekly Reports"
            name="activeWeeklyReport"
            valuePropName="checked"
            initialValue={loadedUser.activeWeeklyReport}
          >
            <Switch />
          </Form.Item>
          <Form.Item
            label="Onboarding State"
            name="onboardingStep"
            initialValue={loadedUser.onboardingStep || 5}
          >
            <Select>
              {[1, 2, 3, 4, 5].map((step) => (
                <Select.Option key={step} value={step}>
                  {renderStepText(step)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            label="Timezone"
            name="timezone"
            initialValue={loadedUser.timezone}
          >
            <Select>
              {Object.values(Timezone).map((tz) => (
                <Select.Option key={tz} value={tz}>
                  {tz}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Update user
            </Button>
          </Form.Item>
        </Form>
      ) : (
        'Loading'
      )}
      <Typography.Title level={2}>Forms</Typography.Title>
      <Table
        dataSource={
          (loadedForms
            ? loadedForms.forms.map((fr) => ({ ...fr, key: fr.id }))
            : []) as unknown as DataType[]
        }
        scroll={{ x: true }}
        loading={loadedForms === null}
        columns={columns}
        pagination={false}
        onRow={(record) => ({
          onClick: () => {
            navigate(`${RouterPaths.FORMS}/${record.id}`);
          },
        })}
      />
    </Card>
  );
}

export default UserEdit;
