import { Button, Form, FormInstance, Input } from 'antd';
import FileManager from '../FileManager';
import Image from '../Image';
import React from 'react';
import { NamePath } from 'antd/es/form/interface';
import { RestOutlined } from '@ant-design/icons';

export interface ImageInputProps {
  name: NamePath;
  label: string;
  userId?: number;
  form?: FormInstance;
  nameForUpdate?: NamePath;
  required?: boolean;
  defaultImageId?: number | string | null;
  useAliasId?: boolean;
  allowClear?: boolean;
  accept?: string;
}

function ImageInput({
  name,
  label,
  userId,
  form,
  nameForUpdate,
  required = true,
  defaultImageId = null,
  useAliasId = false,
  allowClear = false,
  accept,
}: ImageInputProps) {
  const formI = form || Form.useFormInstance();
  const imageValue = Form.useWatch(nameForUpdate || name, formI);
  const [fileManagerVisible, setFileManagerVisible] = React.useState(false);

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: '25px',
      }}
    >
      <Form.Item
        label={label}
        name={name}
        rules={[{ required, message: 'Please select image' }]}
        style={{ marginBottom: 0 }}
        initialValue={defaultImageId}
      >
        <Input type="number" style={{ display: 'none' }} />
      </Form.Item>
      {imageValue ? (
        <>
          <Image
            id={!useAliasId ? imageValue : undefined}
            aliasId={useAliasId ? imageValue : undefined}
            style={{ height: '32px', margin: '0 20px' }}
          />
          <Button
            onClick={() => {
              formI.setFieldValue(nameForUpdate || name, null);
            }}
            danger
            icon={<RestOutlined />}
          />
        </>
      ) : null}
      <Button
        type="dashed"
        onClick={() => {
          setFileManagerVisible(true);
        }}
      >
        Select logo
      </Button>
      <FileManager
        visible={fileManagerVisible}
        userId={userId}
        onFileSelect={(imageId, image) => {
          formI.setFieldValue(
            nameForUpdate || name,
            useAliasId ? image.aliasId : imageId,
          );
          setFileManagerVisible(false);
        }}
        onClose={() => {
          setFileManagerVisible(false);
        }}
        useAliasId={useAliasId}
        accept={accept}
      />
    </div>
  );
}

export default ImageInput;
