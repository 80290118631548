import { FormNewFormState, StepsFormState } from '..';
import {
  DesignType,
  InputType,
  ViewType,
} from '../../../services/api/types/steps';

export const GarageDoorWizardTemplate: {
  formF: FormNewFormState;
  stepsF: StepsFormState;
} = {
  formF: {
    name: 'Example Garage Door Form',
    slug: 'example-garage-door-form',
    brandColor: '189ED9',
    origin: 'https://example.com/',
    widgetTitle: 'Get estimate for free',
    widgetButtonText: 'Start estimation',
    logoImageId: null as any,
    developmentMode: false,
    requirePhone: true,
    createDefaultPopup: true,
    requireAdditionalNotes: true,
    requireAddress: true,
    linkable: true,
    resultScreenButtonRedirect: null,
    resultScreenRedirect: null,
  },
  stepsF: {
    steps: [
      {
        title: 'What type of garage door interests you?',
        order: 0,
        isFinalStep: false,
        submitButtonText: 'Continue',
        type: InputType.SINGLE_SELECT,
        designType: DesignType.CARDS,
        viewType: ViewType.GRID,
        nextQuestionIndex: 1,
        dataAnnotation: null,
        options: [
          {
            value: 'Residential',
            resultTitle: 'Residential Result  Title',
            resultDescription: 'Residential Result Description',
            order: 0,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Commercial',
            resultTitle: 'Commercial Result  Title',
            resultDescription: 'Commercial Result Description',
            order: 1,
            imageId: null as any,
            nextQuestionIndex: null,
          },
        ],
      },
      {
        title: 'Would you prefer a single or double garage door?',
        order: 1,
        isFinalStep: false,
        submitButtonText: 'Continue',
        type: InputType.SINGLE_SELECT,
        designType: DesignType.CARDS,
        viewType: ViewType.GRID,
        nextQuestionIndex: 2,
        dataAnnotation: null,
        options: [
          {
            value: 'Single door',
            order: 0,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Double door',
            order: 1,
            imageId: null as any,
            nextQuestionIndex: null,
          },
        ],
      },
      {
        title: 'What type of garage door you need?',
        order: 2,
        isFinalStep: false,
        submitButtonText: 'Continue',
        type: InputType.SINGLE_SELECT,
        designType: DesignType.CARDS,
        viewType: ViewType.GRID,
        nextQuestionIndex: 3,
        dataAnnotation: null,
        options: [
          {
            value: 'Tilt up',
            order: 0,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Roll up',
            order: 1,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Other',
            order: 2,
            imageId: null as any,
            nextQuestionIndex: null,
          },
        ],
      },
      {
        title: 'What style of garage door are you considering?',
        order: 3,
        isFinalStep: false,
        submitButtonText: 'Continue',
        type: InputType.SINGLE_SELECT,
        designType: DesignType.CARDS,
        viewType: ViewType.GRID,
        nextQuestionIndex: 4,
        dataAnnotation: null,
        options: [
          {
            value: 'Vinyl',
            order: 0,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Aluminum',
            order: 1,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Wood',
            order: 2,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Unsure',
            order: 3,
            imageId: null as any,
            nextQuestionIndex: null,
          },
        ],
      },
      {
        title: 'Do you need door handles?',
        order: 4,
        isFinalStep: false,
        submitButtonText: 'Continue',
        type: InputType.SINGLE_SELECT,
        designType: DesignType.CARDS,
        viewType: ViewType.GRID,
        nextQuestionIndex: 5,
        dataAnnotation: null,
        options: [
          {
            value: 'Yes',
            order: 0,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'No',
            order: 1,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Unsure',
            order: 2,
            imageId: null as any,
            nextQuestionIndex: null,
          },
        ],
      },
      {
        title: 'When would you like this service to be done?',
        order: 5,
        isFinalStep: false,
        submitButtonText: 'Continue',
        type: InputType.SINGLE_SELECT,
        designType: DesignType.CARDS,
        viewType: ViewType.GRID,
        nextQuestionIndex: 6,
        dataAnnotation: null,
        options: [
          {
            value: 'As soon as possible',
            order: 0,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: `I'm flexible`,
            order: 1,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Planning and budgeting for future',
            order: 2,
            imageId: null as any,
            nextQuestionIndex: null,
          },
        ],
      },
      {
        title:
          'Alright, you are one step away from your free instant estimate!',
        order: 6,
        isFinalStep: true,
        submitButtonText: 'Submit For An Estimation',
        type: null,
        designType: DesignType.CARDS,
        viewType: ViewType.GRID,
        options: undefined as any,
        nextQuestionIndex: null,
        dataAnnotation: null,
      },
    ],
  },
};
