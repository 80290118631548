import { combineReducers } from '@reduxjs/toolkit';
import { PersistConfig } from 'redux-persist';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';
import { UserState } from './types/user';
import userReducer from './user.reducer';

const persistUserConfig: PersistConfig<UserState> = {
  key: 'user',
  storage,
};

const rootReducer = combineReducers({
  user: persistReducer(persistUserConfig, userReducer),
});

export default rootReducer;
