import { Form, FormInstance, message, Select } from 'antd';
import { NamePath } from 'antd/es/form/interface';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/selectors/user';
import { SubscriptionDto } from '../../services/api/types/billing';
import React, { useEffect } from 'react';
import { apiListAvailableSubscriptions } from '../../services/api/billing';

interface SubscriptionSelectorProps {
  name?: NamePath;
  label?: string;
  defaultValue?: string;
  form?: FormInstance;
  required?: boolean;
  onChange?: (value: string, subscription: SubscriptionDto | null) => void;
  value?: string;
  style?: React.CSSProperties;
}

function SubscriptionSelector({
  name,
  label = 'Select Subscription',
  defaultValue,
  form,
  required = true,
  value,
  onChange,
  style,
}: SubscriptionSelectorProps) {
  const { token } = useSelector(selectUserState);
  const [loadedSubscriptions, setLoadedSubscriptions] = React.useState<
    SubscriptionDto[] | null
  >(null);

  async function loadSubscriptions(token: string) {
    const [res, status] = await apiListAvailableSubscriptions(token, {});

    if (status === 201) {
      setLoadedSubscriptions(res.subscriptions);
    } else {
      message.error({ content: 'Failed to load subscriptions!' });
    }
  }

  useEffect(() => {
    if (token) {
      loadSubscriptions(token);
    }
  }, [token]);

  if (onChange) {
    return (
      <Form.Item
        name={name}
        label={label}
        initialValue={defaultValue}
        style={style}
      >
        <Select
          loading={loadedSubscriptions === null}
          value={value}
          onChange={(type) => {
            const sub = loadedSubscriptions?.find((s) => s.type === type);
            if (sub) {
              onChange(type, sub);
            } else {
              onChange(type, null);
            }
          }}
          disabled={loadedSubscriptions === null}
        >
          {loadedSubscriptions?.map((sub) => (
            <Select.Option key={sub.id} value={sub.type}>
              {sub.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    );
  }

  return (
    <Form.Item
      name={name}
      label={label}
      initialValue={defaultValue}
      style={style}
    >
      <Select loading={loadedSubscriptions === null}>
        {loadedSubscriptions?.map((sub) => (
          <Select.Option key={sub.id} value={sub.type}>
            {sub.name}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
}

export default SubscriptionSelector;
