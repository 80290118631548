import { API_URL } from './constants';
import {
  ApiFormsCreatePopupRequestBody,
  ApiFormsCreatePopupResponse,
  ApiFormsCreateWizardRequestBody,
  ApiFormsDeletePopupRequestBody,
  ApiFormsDeletePopupResponse,
  ApiFormsGetFormMetaDataRequestBody,
  ApiFormsGetFormMetaDataResponse,
  ApiFormsGetResponse,
  ApiFormsListResponse,
  ApiFormsUpdateFormMetaDataRequestBody,
  ApiFormsUpdateFormMetaDataResponse,
  ApiFormsUpdateFormStylesRequestBody,
  ApiFormsUpdateFormStylesResponse,
  ApiFormsUpdateFormWidgetRequestBody,
  ApiFormsUpdateFormWidgetResponse,
  ApiFormsUpdateRequestBody,
  ApiFormsUpdateResponse,
  FormEntity,
  WorkspaceUserRole,
} from './types/forms';

export async function apiFormsCreateWizard(
  body: ApiFormsCreateWizardRequestBody,
  token: string,
): Promise<any | null> {
  const url = new URL(`${API_URL}/admin/forms/create-wizard`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [await response.json(), response.status];
}

export async function apiFormsCreatePopup(
  body: ApiFormsCreatePopupRequestBody,
  token: string,
): Promise<[ApiFormsCreatePopupResponse, number]> {
  const url = new URL(`${API_URL}/admin/forms/create-popup`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [await response.json(), response.status];
}

export async function apiFormsDeletePopup(
  body: ApiFormsDeletePopupRequestBody,
  token: string,
): Promise<[ApiFormsDeletePopupResponse, number]> {
  const url = new URL(`${API_URL}/admin/forms/delete-popup`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [await response.json(), response.status];
}

export async function apiFormsList(
  token: string,
  userId?: number,
): Promise<[ApiFormsListResponse, number]> {
  const url = new URL(
    `${API_URL}/admin/forms/list${userId ? `?userId=${userId}` : ''}`,
  ).href;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return [(await response.json()) as ApiFormsListResponse, response.status];
}

export async function apiFormsGet(
  token: string,
  formId?: number,
): Promise<[ApiFormsGetResponse, number]> {
  const url = new URL(
    `${API_URL}/admin/forms/get${formId ? `?formId=${formId}` : ''}`,
  ).href;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
  return [(await response.json()) as ApiFormsGetResponse, response.status];
}

export async function apiFormsUpdate(
  token: string,
  body: ApiFormsUpdateRequestBody,
): Promise<[ApiFormsUpdateResponse, number]> {
  const url = new URL(`${API_URL}/admin/forms/update`).href;
  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [(await response.json()) as ApiFormsUpdateResponse, response.status];
}

export async function apiFormsGetFormMetadata(
  token: string,
  query: ApiFormsGetFormMetaDataRequestBody,
): Promise<[ApiFormsGetFormMetaDataResponse, number]> {
  const url = new URL(
    `${API_URL}/admin/forms/get-form-meta-data?formId=${query.formId}`,
  ).href;
  const response = await fetch(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return [
    (await response.json()) as ApiFormsGetFormMetaDataResponse,
    response.status,
  ];
}

export async function apiFormsUpdateFormMetaData(
  token: string,
  body: ApiFormsUpdateFormMetaDataRequestBody,
): Promise<[ApiFormsUpdateFormMetaDataResponse, number]> {
  const url = new URL(`${API_URL}/admin/forms/update-form-meta-data`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [
    (await response.json()) as ApiFormsUpdateFormMetaDataResponse,
    response.status,
  ];
}

export function getOwnerOfTheForm(formEntity: FormEntity | undefined | null) {
  if (formEntity?.workspace?.team) {
    return (
      formEntity.workspace.team.find(
        (team) => team.role === WorkspaceUserRole.OWNER,
      )?.user ?? null
    );
  }
  return null;
}

export async function apiFormsUpdateStyles(
  token: string,
  body: ApiFormsUpdateFormStylesRequestBody,
): Promise<[ApiFormsUpdateFormStylesResponse, number]> {
  const url = new URL(`${API_URL}/admin/forms/update-form-styles`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [
    (await response.json()) as ApiFormsUpdateFormStylesResponse,
    response.status,
  ];
}

export async function apiFormsUpdateWidget(
  token: string,
  body: ApiFormsUpdateFormWidgetRequestBody,
): Promise<[ApiFormsUpdateFormWidgetResponse, number]> {
  const url = new URL(`${API_URL}/admin/forms/update-form-widget`).href;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  });
  return [
    (await response.json()) as ApiFormsUpdateFormWidgetResponse,
    response.status,
  ];
}
