import React, { useEffect } from 'react';
import {
  InvoiceDto,
  ListInvoicesResponse,
} from '../../services/api/types/billing';
import { Button, message, Table, TableProps } from 'antd';
import { useSelector } from 'react-redux';
import { selectUserState } from '../../store/selectors/user';
import { useSearchParams } from 'react-router-dom';
import {
  apiDownloadInvoice,
  apiListInvoices,
} from '../../services/api/billing';

interface InvoicesTableProps {
  userId: string;
}

interface InvoiceRow extends InvoiceDto {
  key: string;
}

const PER_PAGE_LIMIT = 10;

function InvoicesTable({ userId }: InvoicesTableProps) {
  const { token } = useSelector(selectUserState);
  const [invoices, setInvoices] = React.useState<ListInvoicesResponse | null>(
    null,
  );

  const [params, setSearchParams] = useSearchParams({ pageOffset: '0' });
  const pageOffset = params.get('pageOffset');
  const [paging, setPaging] = React.useState<{ offset: number; limit: number }>(
    {
      offset: pageOffset ? parseInt(pageOffset) : 0,
      limit: PER_PAGE_LIMIT,
    },
  );
  const [downloadingInvoices, setDownloadingInvoices] = React.useState<
    string[]
  >([]);

  async function loadInvoices() {
    if (token && userId && paging) {
      const [res, status] = await apiListInvoices(token, {
        userId,
        pagination: { offset: paging.offset, limit: paging.limit },
      });
      if (status === 201) {
        setInvoices(res);
      } else {
        message.error('Failed to load invoices');
      }
    }
  }

  async function downloadInvoice(invoice: InvoiceDto) {
    if (token && userId) {
      setDownloadingInvoices([...downloadingInvoices, invoice.id]);
      const [res, status] = await apiDownloadInvoice(token, {
        invoiceId: invoice.id,
        userId,
      });

      if (status === 201 && res.downloadUrl) {
        window.open(res.downloadUrl, '_blank');
      } else {
        message.error('Failed to download invoice');
      }
      setDownloadingInvoices(
        downloadingInvoices.filter((id) => id !== invoice.id),
      );
    }
  }

  useEffect(() => {
    loadInvoices();
  }, [paging, userId, token]);

  const columns: TableProps<InvoiceRow>['columns'] = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
    },
    {
      title: 'Created At',
      key: 'createdAt',
      dataIndex: 'createdAt',
    },
    {
      title: 'Price',
      key: 'price',
      dataIndex: 'priceCent',
      render: (priceCent) => `${priceCent / 100}`,
    },
    {
      title: 'Currency',
      key: 'currency',
      dataIndex: 'currency',
    },
    {
      title: 'ID',
      key: 'id',
      dataIndex: 'id',
    },
    {
      title: 'Actions',
      key: 'actions',
      dataIndex: 'id',
      render: (_, record) => {
        return (
          <Button
            loading={downloadingInvoices.includes(record.id)}
            onClick={() => {
              downloadInvoice(record);
            }}
          >
            Download
          </Button>
        );
      },
    },
  ];

  const dataSource =
    invoices?.invoices.map((invoice) => ({
      ...invoice,
      key: invoice.id,
    })) ?? [];

  return (
    <Table
      size="small"
      loading={invoices === null}
      scroll={{ x: true }}
      columns={columns}
      dataSource={dataSource}
      pagination={{
        total: invoices?.pagination?.total || 0,
        current: paging.offset / paging.limit + 1,
      }}
      onChange={(pagination) => {
        if (pagination.current) {
          const size = pagination.pageSize || PER_PAGE_LIMIT;
          const offset = size * (pagination.current - 1);
          setPaging({
            offset,
            limit: size,
          });
          setSearchParams(
            {
              pageOffset: offset.toString(),
            },
            { replace: true },
          );
        }
      }}
    />
  );
}

export default InvoicesTable;
