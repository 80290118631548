import { FormNewFormState, StepsFormState } from '..';
import {
  DesignType,
  InputType,
  ViewType,
} from '../../../services/api/types/steps';

export const CleaningWizardTemplate: {
  formF: FormNewFormState;
  stepsF: StepsFormState;
} = {
  formF: {
    name: 'Example Cleaning Form',
    slug: 'example-cleaning-form',
    brandColor: '189ED9',
    origin: 'https://example.com/',
    widgetTitle: 'Get estimate for free',
    widgetButtonText: 'Start estimation',
    logoImageId: null as any,
    developmentMode: false,
    requirePhone: true,
    createDefaultPopup: true,
    requireAdditionalNotes: true,
    requireAddress: true,
    linkable: true,
    resultScreenButtonRedirect: null,
    resultScreenRedirect: null,
  },
  stepsF: {
    steps: [
      {
        title: 'What type of service do you require?',
        order: 0,
        isFinalStep: false,
        submitButtonText: 'Continue',
        type: InputType.SINGLE_SELECT,
        designType: DesignType.CARDS,
        viewType: ViewType.GRID,
        nextQuestionIndex: 1,
        dataAnnotation: null,
        options: [
          {
            value: 'Residential Cleaning',
            order: 0,
            resultTitle: 'Residential Cleaning Result Title',
            resultDescription: 'Residential Cleaning Result Description',
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Commercial Cleaning',
            order: 1,
            resultTitle: 'Commercial Cleaning Result Title',
            resultDescription: 'Commercial Cleaning Result Description',
            imageId: null as any,
            nextQuestionIndex: null,
          },
        ],
      },
      {
        title: 'What size is the space you want to be cleaned?',
        order: 1,
        isFinalStep: false,
        submitButtonText: 'Continue',
        type: InputType.SINGLE_SELECT,
        designType: DesignType.CARDS,
        viewType: ViewType.GRID,
        nextQuestionIndex: 2,
        dataAnnotation: null,
        options: [
          {
            value: 'Small',
            order: 0,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Medium',
            order: 1,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Large',
            order: 2,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Very Large',
            order: 3,
            imageId: null as any,
            nextQuestionIndex: null,
          },
        ],
      },
      {
        title:
          'How often you would like your property to receive cleaning services?',
        order: 2,
        isFinalStep: false,
        submitButtonText: 'Continue',
        type: InputType.SINGLE_SELECT,
        designType: DesignType.CARDS,
        viewType: ViewType.GRID,
        nextQuestionIndex: 3,
        dataAnnotation: null,
        options: [
          {
            value: 'Weekly',
            order: 0,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Bi-Weekly',
            order: 1,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Monthly',
            order: 2,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'One time service',
            order: 3,
            imageId: null as any,
            nextQuestionIndex: null,
          },
        ],
      },
      {
        title: 'Do you need any additional services?',
        order: 3,
        isFinalStep: false,
        submitButtonText: 'Continue',
        type: InputType.SINGLE_SELECT,
        designType: DesignType.CARDS,
        viewType: ViewType.GRID,
        nextQuestionIndex: 4,
        dataAnnotation: null,
        options: [
          {
            value: 'Ironing',
            order: 0,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Fridge Cleaning',
            order: 1,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Oven Cleaning',
            order: 2,
            imageId: null as any,
            nextQuestionIndex: null,
          },
        ],
      },
      {
        title: 'When would you like this service to be done?',
        order: 4,
        isFinalStep: false,
        submitButtonText: 'Continue',
        type: InputType.SINGLE_SELECT,
        designType: DesignType.CARDS,
        viewType: ViewType.GRID,
        nextQuestionIndex: 5,
        dataAnnotation: null,
        options: [
          {
            value: 'As soon as possible',
            order: 0,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: `I'm flexible`,
            order: 1,
            imageId: null as any,
            nextQuestionIndex: null,
          },
          {
            value: 'Planning and budgeting for future',
            order: 2,
            imageId: null as any,
            nextQuestionIndex: null,
          },
        ],
      },
      {
        title:
          'Alright, you are one step away from your free instant estimate!',
        order: 5,
        nextQuestionIndex: null,
        isFinalStep: true,
        type: null,
        designType: DesignType.CARDS,
        viewType: ViewType.GRID,
        submitButtonText: 'Submit For An Estimation',
        options: undefined as any,
        dataAnnotation: null,
      },
    ],
  },
};
